import React, { useState } from "react";
import classes from "./Sidebar.module.css";
import Checkbox from "../../components/Checkbox/Checkbox";
import technicalReports from "../../img/technical-reports.png";
import scientificPapers from "../../img/scientific-papers.png";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface FileType {
  name: string;
}

interface SidebarProps {
  fileTypes: FileType[];
  passDateFilterValues: (fromDate: string, toDate: string) => void;
  passFileTypeFilterValues: (fileType: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  fileTypes,
  passDateFilterValues,
  passFileTypeFilterValues,
}) => {
  const { t } = useTranslation()
  const [researchDropdownOpen, setResearchDropdownOpen] =
    useState<boolean>(false);
  // const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [showAllCheckboxes, setShowAllCheckboxes] = useState<boolean>(false);

  const [selectedFileTypes, setSelectedFileTypes] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>("01-01-1901");

  const toggleResearchDropdown = () => {
    setResearchDropdownOpen(!researchDropdownOpen);
  };

  // const handleRadioChange = (value: string) => {
  //   // Create a new array to toggle the selection state of the checkbox
  //   let newSelectedOptions = [...selectedOptions];
  //   if (newSelectedOptions.includes(value)) {
  //     newSelectedOptions = newSelectedOptions.filter(
  //       (option) => option !== value
  //     );
  //   } else {
  //     newSelectedOptions.push(value);
  //   }
  //   setSelectedOptions(newSelectedOptions);
  //   console.log(newSelectedOptions);
  // };

  const toggleShowAllCheckboxes = () => {
    setShowAllCheckboxes(!showAllCheckboxes);
  };

  const handleDateChange = (value: string) => {
    // Calculate from and to dates based on the selected value
    let fromDate = "01-01-1901";
    let toDate = moment(new Date()).format('DD-MM-YYYY');

    // Set the selected date
    if(selectedDate !== value) {
      setSelectedDate(value);
      switch (value) {
        case `${t("Today")}`:
          fromDate = toDate
          break;
        case `${t("Last 7 days")}`:
          fromDate = moment(new Date()).subtract(7, 'days').format('DD-MM-YYYY')
          break;
        case `${t("Last month")}`:
          fromDate = moment(new Date()).subtract(1, 'month').format('DD-MM-YYYY')
          break;
        case `${t("Last year")}`:
          fromDate = moment(new Date()).subtract(1, 'year').format('DD-MM-YYYY')
          break;
        default:
          break;
      }
    } else {
      setSelectedDate("01-01-1901")
    }
    let newSelectedFileTypes = [...selectedFileTypes];

    passDateFilterValues(fromDate, toDate);
    const fileTypeString = newSelectedFileTypes.join(",");
    passFileTypeFilterValues(fileTypeString);
  };

  const handleFileTypeChange = (value: string) => {
    // Create a new array to toggle the selection state of the file type checkbox
    let newSelectedFileTypes = [...selectedFileTypes];
    if (newSelectedFileTypes.includes(value)) {
      newSelectedFileTypes = newSelectedFileTypes.filter(
        (option) => option !== value
      );
    } else {
      newSelectedFileTypes.push(value);
    }
    setSelectedFileTypes(newSelectedFileTypes);

    // Create a string with selected file types separated by commas
    const fileTypeString = newSelectedFileTypes.join(",");
    console.log(`type=${fileTypeString}`);
    // This will log something like: type=pdf,xlsx

    // Create fromDate and toDate variables for the API call
    const today = new Date();
    const fromDate = `${today.getDate().toString().padStart(2, "0")}-${(
      today.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${today.getFullYear()}`;
    const toDate = fromDate;

    console.log("selectedDate: ", selectedDate);
    console.log("selectedToDate: ", toDate);
    // Now, you can integrate this into your API call or pass it to the parent component as needed
    passFileTypeFilterValues(fileTypeString);
    passDateFilterValues(selectedDate, toDate);
  };

  // const isOptionSelected = (value: string) => selectedOptions.includes(value);

  return (
    <div className={classes.sidebar}>
      <div className={classes.menu}>
        <div className={classes.subItems}>
          <div className={classes.submenu}>
            <div>
              <span className={classes.submenuTitle}>{t("File type")}</span>
            </div>
            <div className={classes.sub2Items}>
              <div>
                {fileTypes
                  .slice(0, showAllCheckboxes ? fileTypes.length : 4)
                  .map((fileType) => (
                    <div key={fileType.name}>
                      <Checkbox
                        id={fileType.name}
                        name="fileType"
                        value={fileType.name}
                        text={t(fileType.name)}
                        onChange={() => handleFileTypeChange(fileType.name)}
                        checked={selectedFileTypes.includes(fileType.name)}
                      />
                    </div>
                  ))}
              </div>
            </div>
            {fileTypes.length > 4 && (
              <button onClick={toggleShowAllCheckboxes}>
                {showAllCheckboxes ? t("Show less") : t("Show all")}
              </button>
            )}
          </div>
          <div className={classes.submenu}>
          {t("Time period")}
            <div className={classes.subItems}>
              <div>
                <Checkbox
                  id="Today"
                  name="timePeriod"
                  value="Today"
                  text={t("Today")}
                  onChange={() => handleDateChange("Today")}
                  checked={selectedDate === "Today"}
                />
              </div>
              <div>
                <Checkbox
                  id="Last 7 days"
                  name="timePeriod"
                  value="Last 7 days"
                  text={t("Last 7 days")}
                  onChange={() => handleDateChange("Last 7 days")}
                  checked={selectedDate === "Last 7 days"}
                />
              </div>
              <div>
                <Checkbox
                  id="Last month"
                  name="timePeriod"
                  value="Last month"
                  text={t("Last month")}
                  onChange={() => handleDateChange("Last month")}
                  checked={selectedDate === "Last month"}
                />
              </div>
              <div>
                <Checkbox
                  id="Last year"
                  name="timePeriod"
                  value="Last year"
                  text={t("Last year")}
                  onChange={() => handleDateChange("Last year")}
                  checked={selectedDate === "Last year"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
