import React, { useEffect, useRef } from "react";
import Chart, { ChartEvent } from "chart.js/auto";
import { useTranslation } from "react-i18next";

interface DataItem {
  type: string;
  species: number;
  color: string;
}

interface BarChartProps {
  data: DataItem[];
  year: any
}

const BarChart: React.FC<BarChartProps> = ({ data, year }) => {
  const { t } = useTranslation()

  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart<"pie"> | null>(null);

  useEffect(() => {
    const ctx = chartRef.current?.getContext("2d");
    if (!ctx) return;

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    chartInstanceRef.current = new Chart(ctx, {
      type: "pie",
      data: {
        labels: data?.map(({ type }) => type),
        datasets: [
          {
            label: `${t("Population")}`,
            data: data?.map(({ species }) => species),
            backgroundColor: data?.map(d => d.color),
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 5,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: `${t("Year")}: ${year}`
          }
        }
      }
    });

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;

};

export default BarChart;
