import React, { useEffect, useState } from 'react'
import classes from './supportPage.module.css'
import HelmetComponent from '../../components/Helmet'
import { PlusOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import { Button, Col, Collapse, List, Row, Space } from 'antd';

const SupportPage = ({ title }: any) => {
    const { t } = useTranslation();

    const dataAboutUs = [
        {
            description: `${t("About Us Text 1")}`,
        },
        {
            description: `${t("About Us Text 2")}`,
        },
        {
            description: `${t("About Us Text 3")}`,
        },
        {
            description: `${t("About Us Text 4")}`,
        },
    ];
    
    const dataPrivacyPolicy = [
        {
            description: `${t("Privacy Policy Text 1")}`,
        },
        {
            description: `${t("Privacy Policy Text 2")}`,
        },
        {
            description:  `${t("Privacy Policy Text 3")}`,
        },
        {
            description: `${t("Privacy Policy Text 4")}`,
        },
        {
            description: `${t("Privacy Policy Text 5")}`,
        },
        {
            description: `${t("Privacy Policy Text 6")}`,
        },
    ];
    
    const dataFAQs = [
        { 
            title: `${t("Question 1")}`, 
            description: `${t("Answer 1")}`, 
        },
        { 
            title: `${t("Question 2")}`, 
            description: `${t("Answer 2")}`, 
        },
        { 
            title: `${t("Question 3")}`, 
            description: `${t("Answer 3")}`, 
        },
        { 
            title: `${t("Question 4")}`, 
            description: `${t("Answer 4")}`, 
        },
        { 
            title: `${t("Question 5")}`, 
            description: `${t("Answer 5")}`, 
        }
    ]

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },[])

    const getHeader = () => {
        if(title === "FAQs") return classes.headerFAQImage
        else if(title === "ABOUT US") return classes.headerAboutUsImage
        else if(title === "PRIVACY POLICY") return classes.headerPrivacyPolicyImage
    }

    return (
        <>
            <HelmetComponent
                title={t(`MENR | ${title}`) || undefined}
                description={t("SEO Home description") || undefined}
            />

            <div className={getHeader()}>
                <h2 className={classes.title}>{t(title)}</h2>
            </div>

            <div className={classes.pageContainer}>
                <Row gutter={50} style={{justifyContent: 'center'}}>
                    <Col className="gutter-row" sm={20} lg={10} style={{marginBottom: '2rem'}}>
                        {title === "FAQs" ? (
                            <>
                                <h2 className={classes.subtitle}>{t("Frequently")}</h2>
                                <h2 className={classes.subtitle} style={{marginTop: '-0.5rem'}}>{t("Asked Questions")}</h2>
                                <h4 className={classes.caption}>{t("Do you still have questions?")}</h4>
                            </>
                        ) : ( 
                            <h2 className={classes.subtitle}>{t(title)}</h2>
                        )}
                    </Col >
                    <Col className="gutter-row" sm={20} lg={12}>
                        {title === "FAQs" ? (
                            <Space direction="vertical">
                                {dataFAQs.map((data, index) => 
                                    <Collapse
                                        className={classes.collapse}
                                        collapsible="header"
                                        expandIcon={({ isActive }) => 
                                            <Button className={classes.collapseButton}>
                                                <PlusOutlined className={ isActive ? classes.collapseIconOpen : classes.collapseIconClosed}/>
                                            </Button>
                                        }
                                        expandIconPosition='end'
                                        items={[
                                            {
                                                key: '1',
                                                label: <h4 className={classes.collapseTitle}>{data.title}</h4>,
                                                children: <p className={classes.collapseText}>{data.description}</p>
                                            },
                                        ]}
                                    />
                                )}
                            </Space>
                        ) : (
                            <List
                                className={classes.infoList}
                                itemLayout="horizontal"
                                dataSource={title === "ABOUT US" ? dataAboutUs : dataPrivacyPolicy}
                                renderItem={(item) => (
                                <List.Item className={classes.text}>
                                    <List.Item.Meta 
                                        title={ <h4><span style={{textDecoration: title === 'PRIVACY POLICY' ? 'underline' : ''}}>{item.description.split(":")[0]}</span>:{item.description.split(":")[1]}</h4>}
                                    />
                                </List.Item>
                                )}
                            />
                        )}
                    </Col >
                </Row>
            </div>
        </>
    )
}

export default SupportPage