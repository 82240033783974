import { useTranslation } from "react-i18next";
import { Col, Row } from 'antd';
import classes from "./Footer.module.css"
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";

const Footer = () => {
    const { t } = useTranslation()

    return (
        <div style={{backgroundColor: "#1C1E53"}}>
            <div className={classes.footerContainer}>
                <div className={classes.width}>
                    <div className={classes.footer}>
                        <Row>
                            <Col sm={24} lg={16}>
                                <Logo />
                                <br />
                                <span className={classes.sublogo}>{t("Data Management System")}</span>
                            </Col>
                            <Col xs={24} sm={24} lg={8} className={classes.linksContainer}>
                                <div>
                                    <h5>{t('National Parks')}</h5>
                                    <p><Link to={'/shirvan'} style={{color: 'white'}}>{t('Shirvan')}</Link></p>
                                    <p><Link to={'/hirkan'} style={{color: 'white'}}>{t('Hirkan')}</Link></p>
                                </div>

                                <div>
                                    <h5>{t('SUPPORT')}</h5>
                                    <p><Link to={'/about-us'} style={{color: 'white'}}>{t('About Us')}</Link></p>
                                    <p><Link to={'/privacy-policy'} style={{color: 'white'}}>{t('Privacy Policy')}</Link></p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className={classes.contact}>
                        <div>
                            <h3>{t("Email")}</h3>
                            <p>contact@website.com</p>
                        </div>
                        <div>
                            <h3>{t('Telephone')}</h3>
                            <p>+0000 111 222 333</p>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default Footer;
