// weatherService.ts

const openWeatherBaseUrl = 'https://api.openweathermap.org/data/2.5';
const apiKey = '8b8a74001922bca725b168139d22f674';

interface WeatherDataProps {
    current: {
      temp: number;
      humidity: number;
      wind_speed: number;
      uvi: number;
      wind_deg: number;
    }
  }
export const fetchWeatherData = async (latitude: number, longitude: number): Promise<WeatherDataProps> => {
  const url = `${openWeatherBaseUrl}/onecall?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch weather data');
    }

    const data = await response.json();
    return data as WeatherDataProps;
  } catch (error) {
    throw new Error('Failed to fetch weather data');
  }
};
