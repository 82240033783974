import React from "react"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"

interface Props{
  title?: string
  description?: string
  image?:string
}

const HelmetComponent: React.FC<Props> = ({ title = 'Title page', description='Descrition page', image=`${process.env.REACT_APP_DOMAIN}/favicon.ico` }: Props) => {

  const { t } = useTranslation()
    return(
      <Helmet>‍
        <title>{t(title)}</title>‍
        <meta name="description" content={t(description) || undefined} />
        <meta name="twitter:site" content={`${process.env.REACT_APP_DOMAIN}`} />
        <meta name="twitter:creator" content="dotsoft.gr" />
        <meta name="twitter:title" content={t(title) || undefined} />
        <meta name="twitter:description" content={t(description) || undefined} />
        <meta name="twitter:image" content={image} />
        <meta property="og:title" content={t(title) || undefined} />
        <meta property="og:description" content={t(description) || undefined} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={`${process.env.REACT_APP_DOMAIN}`} />
        <meta property="og:site_name" content={`${process.env.REACT_APP_SITE_NAME}`} />
        <meta property="og:locale" content="el_GR" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content={`${process.env.REACT_APP_FACEBOOK}`} />
      </Helmet>
  )
}

export default HelmetComponent