import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import PageNotFound from "./pages/404";
import GeospatialData from "./pages/GeospatialData/GeospatialData";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import EnvironmentalData from "./pages/EnvironmentalData/EnvironmentalData";
import DigitalLibrary from "./pages/DigitalLibrary/DigitalLibrary";
import TestLeaflet from "./pages/TestLeaflet/TestLeaflet";
import Navbar from "./components/Navbar/Navbar";
import { I18nextProvider } from "react-i18next";
import ParkManagement from "./pages/MonitoringModule/ParkManagement";
import LoginPrompt from "./components/LoginPrompt/LoginPrompt";
import i18n from "./translation";
import SinglePaper from "./pages/SinglePaper/SinglePaper";
import Hirkan from "./pages/Hirkan/Hirkan";
import Shirvan from "./pages/Shirvan/Shirvan";
import SupportPage from "./pages/SupportPage/SupportPage";

const App: React.FC = () => {
  const initialAccessToken = localStorage.getItem("accessToken");
  const [accessToken, setAccessToken] = useState<string | null>(
    initialAccessToken
  );

  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");
    setAccessToken(storedToken);
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <div>
            <Navbar />
            <Routes>
              <Route index element={<Home />} />
              <Route
                path="/hirkan"
                element={
                  accessToken ? (
                    <Hirkan />
                  ) : (
                    <LoginPrompt url="/hirkan" />
                  )
                }
              />
              <Route
                path="/shirvan"
                element={
                  accessToken ? (
                    <Shirvan />
                  ) : (
                    <LoginPrompt url="/shirvan" />
                  )
                }
              />
              <Route
                path="/environmental-data"
                element={
                  accessToken ? (
                    <EnvironmentalData />
                  ) : (
                    <LoginPrompt url="/environmental-data" />
                  )
                }
              />
              <Route
                path="/geospatial-data"
                element={
                  accessToken ? (
                    <GeospatialData />
                  ) : (
                    <LoginPrompt url="/geospatial-data" />
                  )
                }
              />
              <Route
                path="/digital-library"
                element={
                  accessToken ? (
                    <DigitalLibrary />
                  ) : (
                    <LoginPrompt url="/digital-library" />
                  )
                }
              />
              <Route
                path="/test-leaflet"
                element={
                  accessToken ? (
                    <TestLeaflet />
                  ) : (
                    <LoginPrompt url="/test-leaflet" />
                  )
                }
              />
              <Route
                path="/park-management"
                element={
                  accessToken ? (
                    <ParkManagement />
                  ) : (
                    <LoginPrompt url="/park-management" />
                  )
                }
              />
              <Route
                path="/faq"
                element={
                  accessToken ? (
                    <SupportPage title="FAQs" />
                  ) : (
                    <LoginPrompt url="/faq" />
                  )
                }
              />
              <Route
                path="/about-us"
                element={
                  accessToken ? (
                    <SupportPage title="ABOUT US" />
                  ) : (
                    <LoginPrompt url="/about-us" />
                  )
                }
              />
              <Route
                path="/privacy-policy"
                element={
                  accessToken ? (
                    <SupportPage title="PRIVACY POLICY" />
                  ) : (
                    <LoginPrompt url="/privacy-policy" />
                  )
                }
              />
              <Route path="/digital-library/:id" element={<SinglePaper />} />
              <Route path="/login" element={<LoginPrompt />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
            <Footer />
          </div>
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
