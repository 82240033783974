import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import config, { fetchUserProfile } from "../../config";
import { Col, Row } from "antd";
import userPhoto from "../../img/user-photo.png";
import classes from './Avatar.module.css'
import { useTranslation } from "react-i18next";


const Avatar = () => {
  const { t } = useTranslation()
  const [userProfile, setUserProfile] = useState<any>(null);
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);


  useMemo(async () => {
    if (accessToken) {
      const fetchUserData = async () => {
        try {
          const profile = await fetchUserProfile(accessToken);
          setUserProfile(profile);
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      };

      fetchUserData();
    }
  }, [accessToken]);

  return (
    <>
      {userProfile && (
        <Col xs={3} sm={3} md={3} lg={3}>
          <Row justify="center">
            <div className={classes.userContainer}>
              <Col>
                <img src={userPhoto} alt="User Photo" />
              </Col>
              <Col>
                <div className={classes.userText}>
                  <h5>{t("Hi")}, {userProfile[0].first_name}</h5>
                  <p>{t("Job title")}</p>
                </div>
              </Col>
            </div>
          </Row>
        </Col>
      )}
    </>
  );
};

export default Avatar;
