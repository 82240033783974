import Button from "../../components/Button/Button";
import { Col, Image, Row } from "antd";
import ctaMapImg from "../../img/cta-map-img.png";
import classes from "./CTAMap.module.css";
import { useTranslation } from "react-i18next";

const CTAMap: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={classes.ctaMap}>
      <div className={classes.container}>
        <Row>
          <Col lg={12}>
            <h3>{t("Map title")}</h3>
            <p>{t("Map sub")}</p>
            <Button title={t("Shirvan")} onClick={() => window.open("https://earth.google.com/earth/d/1tNF-9YViLiunr5Ysadx6vW0l5xzWSo5c?usp=sharing", "__blank")} />
            <Button title={t("Hirkan")} onClick={() => window.open("https://earth.google.com/web/@38.47428371,48.69263235,248.48910452a,598.26923394d,35y,0h,60t,0r/data=MikKJwolCiExdE5GLTlZVmlMaXVucjVZc2FkeDZ2VzBsNXh6V1NvNWMgAToDCgEw", "__blank")} />
          </Col>
          <Col lg={12}>
            <Image src={ctaMapImg} preview={false} style={{marginLeft: '2rem', borderRadius: '10px'}} alt="map-img"/>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CTAMap;
