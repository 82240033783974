import classes from "./Card.module.css";
import fileMask from "../../img/file-mask.png";
import { useNavigate } from "react-router-dom";

const cardColors = ['#FDEC06','#81AE41','#B08274','#A5D7F1']

const Card: React.FC<{ number: number; title: string; content: string }> = ({
  title,
  content,
  number,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (number === 1) navigate("/environmental-data");
    else if (number === 2) navigate("/digital-library");
    else if (number === 3) navigate("/geospatial-data");
    else if (number === 4) navigate("/park-management");
  };

  return (
    <div
      className={classes.card}
      style={{backgroundColor: cardColors[number - 1], cursor: 'pointer'}}
      onClick={() => handleClick()}
    >
      <div className={classes.imageMask}>
        <span className={classes.number}>{number}</span>
        <img src={fileMask} alt="file" />
      </div>
      <h3>{title}</h3>
      <p style={{opacity: "0.7" }}>
        {content}
      </p>
    </div>
  );
};

export default Card;
