// const isProduction = process.env.NODE_ENV === "production";

const config = {
  baseUrl: "https://fao-cms.etsn.az",
  clientId: process.env.REACT_APP_CLIENT_ID!,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET!,
  redirectUri: process.env.REACT_APP_REDIRECT_URI!,
};

// Function to fetch user profile using the access token
export const fetchUserProfile = async (accessToken: string) => {
  try {
    const response = await fetch(`${config.baseUrl}/api/users/profile`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // console.log(response)

    if (response.ok) {
      const userProfile = await response.json();
      // console.log('CONFIG: ', userProfile);
      
      return userProfile;
    } else {
      // Handle the error
      localStorage.removeItem('accessToken');
      window.location.reload()
      throw new Error("Failed to fetch user profile");
    }
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw new Error("Failed to fetch user profile");
  }
};

export default config;
