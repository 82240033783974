import i18next from "i18next";
import classes from "./NavLinks.module.css";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

const LanguageSwitcher: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.resolvedLanguage);

  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };

  return(
  <div className={classes.languageSelector}>
    <span
      className={selectedLanguage === 'en' ? classes.activeLanguage : ''}
      onClick={() => handleChangeLanguage('en')}
    >
      EN
    </span>
    <span>/</span>
    <span
      className={selectedLanguage === 'az' ? classes.activeLanguage : ''}
      onClick={() => handleChangeLanguage('az')}
    >
      AZ
    </span>
  </div>);
};

export default LanguageSwitcher;
