import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet";
import Header from "../../components/Header/Header";
import { Col, Row } from "antd";
import BreadcrumbRow from "../../components/BreadcrumbRow/BreadcrumbRow";
import classes from "./SinglePaper.module.css";
import test from "../../img/test.png";
import ekt from "../../img/ekt.png";
import powerpoint from "../../img/powerpoint.png";
import singlePaperMap from "../../img/singlePaperMap.png";
import download from "../../img/download.png";
import { useSelector } from "react-redux";
import { getFileData, selectFileData } from "../../redux/digitalLibrarySlice";
import { useAppDispatch } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { useLocation } from "react-router-dom";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

const SinglePaper: React.FC = () => {
  const fileData = useSelector(selectFileData)
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);

  const location = useLocation()
  const fileID = location.pathname.split('/')[2]
  const { t } = useTranslation();
  const dispatch = useAppDispatch()

  const coords: any = fileData?.spatial === "Hirkan" ? [38.4744241, 48.6902385] : [39.5802415, 49.1192854]
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    //Handle Responsiveness  
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
        
    handleResize();
    dispatch(getFileData({accessToken, fileID}))

    // Bind the event listener
    window.addEventListener('resize', handleResize)

    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('resize', handleResize)
    }
  }, []) 

  return (
    <>
      <HelmetComponent
        title={t("MENR | Single Paper") || undefined}
        description={t("SEO Home description") || undefined}
      />
      <BreadcrumbRow parent={"Digital Library"}/>
      
      <div className={classes.container}>
        <Row style={{justifyContent: 'space-between'}}>
          <Col sm={24} lg={8}>
            <div className={classes.sideContainer}>
              <img src={test} alt="test"  style={{width: '100%'}}/>
              <div className={classes.sideContainerInner}>
                <div className={classes.sideContainerInnerBlue}>
                  <div>
                  <h4>{t(fileData?.name)}</h4>

                    <div className={classes.fileTypeContainer}>
                      <div className={classes.fileType}>
                        <img src={powerpoint} alt="powerpoint" />
                        <span>{t("Power Point")}</span>
                      </div>
                      
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <h4 style={{marginRight: '1rem'}}>{t(fileData?.size)}</h4>
                        <div className={classes.download}>
                          <img src={download} alt="download" onClick={() => window.open(fileData?.file, '__blank')}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col sm={24} lg={16} className={classes.containerInner}>
            <Row gutter={16} style={{padding: '1rem'}}>
              <Col className="gutter-row" span={6}>
                <h5 className={classes.rowTitle}>{t("Title")}</h5> 
              </Col>
              <Col className="gutter-row" span={6}>
                <p className={classes.rowText}>{t(fileData?.name)}</p>
              </Col>
            </Row>
            <Row gutter={16} style={{padding: '1rem'}}>
              <Col className="gutter-row" span={6}>
                <h5 className={classes.rowTitle}>{t("Author")}</h5>
              </Col>
              <Col className="gutter-row" span={6}>
                {fileData?.authors?.length
                  ? fileData?.authors?.map((author: string, index: number) => <p key={`author-${index}`} className={classes.rowText}>{t(author)}</p>)
                  : <p className={classes.rowText}></p>
                }
              </Col>
            </Row>
            <Row gutter={16} style={{padding: '1rem'}}>
              <Col className="gutter-row" span={6}>
                  <h5 className={classes.rowTitle}>{t('Description')}</h5>
              </Col>
              <Col className="gutter-row" span={6}>
                  <p className={classes.rowText}>{t(fileData?.description)}</p>
              </Col>
            </Row>
            <Row gutter={16} style={{padding: '1rem'}}>
              <Col className="gutter-row" span={6}>
                <h5 className={classes.rowTitle}>{t("Contributor")}</h5>
              </Col>
              <Col className="gutter-row" span={6}>
                {fileData?.contributors?.length
                  ? fileData?.contributors?.map((contributor: string, index: number) => <p key={`contributor-${index}`} className={classes.rowText}>{contributor}</p>)
                  : <p className={classes.rowText}></p>
                }
              </Col>
            </Row>
            <Row gutter={16} style={{padding: '1rem'}}>
              <Col className="gutter-row" span={6}>
                <h5 className={classes.rowTitle}>{t("Type")}</h5>
              </Col>
              <Col className="gutter-row" span={6}>
                <p className={classes.rowText}>{t(fileData?.type)}</p>
              </Col>
            </Row>
            <Row gutter={16} style={{padding: '1rem'}}>
              <Col className="gutter-row" span={6}>
                <h5 className={classes.rowTitle}>{t("Coverage")}</h5>
              </Col>
              <Col className="gutter-row" span={6}>
                <p className={classes.rowText}>{t(fileData?.coverage)}</p>
              </Col>
            </Row>
            <Row gutter={16} style={{padding: '1rem'}}>
              <Col className="gutter-row" md={24} lg={6}>
                <h5 className={classes.rowTitle}>{t("Spatial")}</h5>
              </Col>
              <Col className="gutter-row" md={24} lg={18}>
                <MapContainer 
                  center={coords} 
                  zoom={10} 
                  scrollWheelZoom={false}
                  style={{width: width - 100, height: '30rem'}}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={coords}>
                    <Popup>{fileData?.spatial}</Popup>
                  </Marker>
                </MapContainer>
              </Col>
            </Row>
            <Row gutter={16} style={{padding: '1rem'}}>
              <Col className="gutter-row" span={6}>
                <h5 className={classes.rowTitle}>{t("Date")}</h5>
              </Col>
              <Col className="gutter-row" span={6}>
                <p className={classes.rowText}>{fileData?.created}</p>
              </Col>
            </Row>
            <Row gutter={16} style={{padding: '1rem'}}>  
              <Col className="gutter-row" span={6}>
                <h5 className={classes.rowTitle}>{t("Keywords")}</h5>
              </Col>
              <Col className="gutter-row" span={6}>
                {fileData?.keywords?.length
                  ? fileData?.keywords?.map((keyword: string, index: number) => <p key={`keyword-${index}`} className={classes.rowText}>{keyword}</p>)
                  : <p className={classes.rowText}></p>
                }
              </Col>
            </Row>
            <Row gutter={16} style={{padding: '1rem'}}>
              <Col className="gutter-row" span={6}>
                <h5 className={classes.rowTitle}>{t("Source")}</h5>
              </Col>
              <Col className="gutter-row" span={6}>
                <p className={classes.rowText}>{t(fileData?.source)}</p>
              </Col>
            </Row>
            <Row gutter={16} style={{padding: '1rem'}}>
              <Col className="gutter-row" span={6}>
                <h5 className={classes.rowTitle}>{t("Language")}</h5>
              </Col>
              <Col className="gutter-row" span={6}>
                <p className={classes.rowText}>EN</p>
              </Col>
            </Row>
            <Row gutter={16} style={{padding: '1rem'}}>
              <Col className="gutter-row" span={6}>
                <h5 className={classes.rowTitle}>{t("Format")}</h5>
              </Col>
              <Col className="gutter-row" span={6}>
                <p className={classes.rowText}>{fileData?.file?.substr(fileData?.file?.length - 3).toUpperCase()}</p>
              </Col>
            </Row>
            <Row gutter={16} style={{padding: '1rem'}}>  
              <Col className="gutter-row" span={6}>
                <h5 className={classes.rowTitle}>{t("Size")}</h5>
              </Col>
              <Col className="gutter-row" span={6}>
                <p className={classes.rowText}>{fileData?.size}</p>
              </Col>
            </Row>
            <Row gutter={16} style={{padding: '1rem'}}>
              <Col className="gutter-row" span={6}>
                <h5 className={classes.rowTitle}>{t("Publisher")}</h5>
              </Col>
              <Col className="gutter-row" span={6}>
                <p className={classes.rowText}>{t(fileData?.publisher)}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SinglePaper;
