import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import GeoJSONMap from "./GeoJSONMap";
import classes from "./GeospatialData.module.css";
import BreadcrumbRow from "../../components/BreadcrumbRow/BreadcrumbRow";
import HelmetComponent from "../../components/Helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Button, Col, Drawer, Row, Tabs } from "antd";
import Checkbox from "../../components/Checkbox/Checkbox";
import styled from "styled-components";

interface ParkFiles {
  boundaries_file?: string;
  boundaries_label?: string;
  croplands_file?: string;
  croplands_label?: string;
  crops_file?: string;
  crops_label?: string;
  flora_fauna_file?: string;
  flora_fauna_label?: string;
  forests_file?: string;
  forests_label?: string;
  greenhouses_file?: string;
  greenhouses_label?: string;
  highlands_file?: string;
  highlands_label?: string;
  land_use_file?: string;
  land_use_label?: string;
  pastures_file?: string;
  pastures_label?: string;
  protected_file?: string;
  protected_label?: string;
  residential_file?: string;
  residential_label?: string;
  roads_file?: string;
  roads_label?: string;
  sub_urban_file?: string;
  sub_urban_label?: string;
  swamps_file?: string;
  swamps_label?: string;
  wetlands_file?: string;
  wetlands_label?: string;
}

const GeospatialData: React.FC = () => {
  const { t } = useTranslation();
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const [activeTab, setActiveTab] = useState<string>("1");
  const [geoJSONData, setGeoJSONData] = useState<any>();

  console.log("FFF", geoJSONData)
  const [centerCoords, setCenterCoords] = useState<any>();
  const [selectedMapType, setSelectedMapType] =
    useState<string>("croplands_file");
  const [parkFiles, setParkFiles] = useState<ParkFiles | null>(null);

  const onTabChange = (key: string) => {
    setActiveTab(key);
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: `${t("Shirvan")}`,
    },
    {
      key: "0",
      label: `${t("Hirkan")}`,
    },
    {
      key: "2",
      label: `${t("Absheron")}`,
      disabled: true,
    },
    {
      key: "3",
      label: `${t("Ag-Gol")}`,
      disabled: true,
    },
    {
      key: "4",
      label: `${t("Altyaghach")}`,
      disabled: true,
    },
    {
      key: "5",
      label: `${t("Göygöl")}`,
      disabled: true,
    },
    {
      key: "6",
      label: `${t("Gizilaghaj")}`,
      disabled: true,
    },
    {
      key: "7",
      label: `${t("Samur-Yalama")}`,
      disabled: true,
    },
    {
      key: "8",
      label: `${t("Shahdag")}`,
      disabled: true,
    },
    {
      key: "9",
      label: `${t("Zangezur")}`,
      disabled: true,
    },
  ];

  const data = {
    type: "FeatureCollection",
    name: `${t("Shirvan")}`,
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: [
      {
        type: "Feature",
        properties: {
          OBJECTID: 354,
          SITECODE: "AZ0000007",
          SITENAME: "Shirvan",
          MS: "AZ",
          SITETYPE: null,
          INSPIRE_ID: null,
          id: "486",
          SHAPE_Leng: 100637.63879700001,
          SHAPE_Area: 597782957.36800003,
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [49.29319, 39.83068],
                [49.3265, 39.82402],
                [49.34744, 39.80118],
                [49.37409, 39.77643],
                [49.40157, 39.75691],
                [49.40303, 39.75165],
                [49.40303, 39.74765],
                [49.40602, 39.74176],
                [49.40811, 39.73622],
                [49.41264, 39.72715],
                [49.418, 39.71826],
                [49.42317, 39.71082],
                [49.42725, 39.70592],
                [49.42943, 39.70293],
                [49.4297, 39.70157],
                [49.4297, 39.70012],
                [49.42952, 39.69839],
                [49.42816, 39.69748],
                [49.42507, 39.6973],
                [49.41827, 39.69603],
                [49.41219, 39.6944],
                [49.40112, 39.69005],
                [49.38779, 39.68342],
                [49.37282, 39.67244],
                [49.35458, 39.65902],
                [49.34614, 39.65321],
                [49.33653, 39.64314],
                [49.33163, 39.63588],
                [49.32927, 39.62799],
                [49.32619, 39.61819],
                [49.32392, 39.60794],
                [49.31947, 39.59397],
                [49.31358, 39.57764],
                [49.30659, 39.56376],
                [49.30106, 39.55287],
                [49.29316, 39.5359],
                [49.28908, 39.52774],
                [49.2869, 39.5213],
                [49.25892, 39.51374],
                [49.22276, 39.51183],
                [49.17802, 39.51564],
                [49.1647, 39.52706],
                [49.15803, 39.54705],
                [49.15137, 39.57656],
                [49.14852, 39.60511],
                [49.14281, 39.63366],
                [49.13329, 39.67649],
                [49.13329, 39.7041],
                [49.14852, 39.72599],
                [49.16755, 39.74597],
                [49.17041, 39.7612],
                [49.1666, 39.79642],
                [49.17231, 39.82021],
                [49.1942, 39.83354],
                [49.23703, 39.83449],
                [49.29319, 39.83068],
              ],
            ],
          ],
        },
      },
    ],
  };

  const getCenterFromGeoJSON = (data: any) => {
    console.log(data);

    const coordinates = data.features[0].geometry.coordinates;
    let totalLat = 0;
    let totalLng = 0;
    let count = 0;

    coordinates.forEach((polygon: any) => {
      polygon.forEach((ring: any) => {
        ring.forEach((coord: [number, number]) => {
          totalLat += coord[1];
          totalLng += coord[0];
          count++;
        });
      });
    });

    const avgLat = totalLat / count;
    const avgLng = totalLng / count;

    return [avgLat, avgLng] as [number, number];
  };

  const getGeoJSONUrl = (mapType: string): string | undefined => {
    console.log("getGeoJSONUrl parkFiles:", parkFiles);
    console.log("getGeoJSONUrl mapType:", parkFiles);

    if (!parkFiles) {
      return undefined;
    }

    const selectedFile = parkFiles;
    
    console.log("selectedFile: ", parkFiles);

    switch (mapType) {
      case "forests_file":
        return selectedFile.forests_file;
      case "highlands_file":
        return selectedFile.highlands_file;
      case "swamps_file":
        return selectedFile.swamps_file;
      case "wetlands_file":
        return selectedFile.wetlands_file;
      case "croplands_file":
        return selectedFile.croplands_file;
      case "crops_file":
        return selectedFile.crops_file;
      case "protected_file":
        return selectedFile.protected_file;
      case "residential_file":
        return selectedFile.residential_file;
      case "roads_file":
        return selectedFile.roads_file || undefined;
      case "suburban _file":
        return selectedFile.sub_urban_file;
      default:
        return undefined;
    }
  };

  const handleMapChange = async (mapType: string) => {
    console.log("MapType:", mapType);
    setSelectedMapType(mapType);
    try {
      const geoJSONUrl = await getGeoJSONUrl(mapType);
      console.log("URL:", geoJSONUrl)
      console.log("geoJSONUrl: ", geoJSONUrl);

      if (!geoJSONUrl) {
        throw new Error("GeoJSON URL not found");
      }

      const geoJSONResponse = await fetch(geoJSONUrl);
      if (!geoJSONResponse.ok) {
        throw new Error("Failed to fetch GeoJSON data");
      }

      const geoJSONData = await geoJSONResponse.json();
      setGeoJSONData(geoJSONData);

      const centerCoords = getCenterFromGeoJSON(geoJSONData);
      setCenterCoords(centerCoords);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        const environmentalDataResponse = await fetch(
          "https://fao-cms.etsn.az/api/parks",
          {
            headers,
          }
        );

        if (!environmentalDataResponse.ok) {
          throw new Error("Failed to fetch");
        }

        const res = await environmentalDataResponse.json();
        console.log(res)

        setParkFiles(res[+activeTab].files[0][selectedMapType] as ParkFiles);

        const geoJSONUrl = res[activeTab].files[0][selectedMapType];

        const geoJSONResponse = await fetch(geoJSONUrl);
        if (!geoJSONResponse.ok) {
          throw new Error("Failed to fetch GeoJSON data");
        }

        const geoJSONData = await geoJSONResponse.json();
        setGeoJSONData(geoJSONData);

        // Calculate center coordinates after fetching GeoJSON data
        const centerCoords = getCenterFromGeoJSON(geoJSONData);
        setCenterCoords(centerCoords);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accessToken, activeTab, selectedMapType]);

  const [width, setWidth] = useState(window.innerWidth)

  const [open, setOpen] = useState(width > 991 ? true : false);

  useEffect(() => {
    //Handle Responsiveness  
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
        
    handleResize();
    
    // Bind the event listener
    window.addEventListener('resize', handleResize)

    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // useEffect(() => {}, [selectedMapType, centerCoords]);

  return (
    <>
      <HelmetComponent
        title={t("MENR | Geospatial Data") || undefined}
        description={t("SEO Home description") || undefined}
      />

      <BreadcrumbRow title={"Geospatial Data"} />

      <h2 className={classes.title}>{t("Geospatial Data")}</h2>

      <Tabs
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        className={classes.tabs}
      />

      <div className={classes.Container}>
        <div className={classes.filtersContainer}>
          <ButtonWrapper className={classes.filtersButton} type="primary" onClick={showDrawer}>
            Filters
          </ButtonWrapper>

          <DrawerWrapper
            title={
              <div className={classes.drawerTitleContainer}>
                <h2>Filters</h2>
                <Button className={classes.titleButton} onClick={onClose}>Done</Button>
              </div>
            }
            contentWrapperStyle={{ boxShadow: 'none' }}
            mask={false}
            placement="left"
            closable={false}
            onClose={onClose}
            open={open}
            getContainer={false}
          >
            <div className={classes.submenu}>
              <u>{t("Habitats")}</u>
              <div className={classes.subItems}>
                <div>
                  <Checkbox
                    id="forests_file"
                    name="mapType"
                    value="forests_file"
                    text={t("Forests")}
                    onChange={() => handleMapChange("forests_file")}
                    checked={selectedMapType === "forests_file"}
                  />
                </div>
                <div>
                  <Checkbox
                    id="highlands_file"
                    name="mapType"
                    value="highlands_file"
                    text={t("Highlands")}
                    onChange={() => handleMapChange("highlands_file")}
                    checked={selectedMapType === "highlands_file"}
                  />
                </div>
                <div>
                  <Checkbox
                    id="swamps_file"
                    name="mapType"
                    value="swamps_file"
                    text={t("Swamps")}
                    onChange={() => handleMapChange("swamps_file")}
                    checked={selectedMapType === "swamps_file"}
                  />
                </div>
                <div>
                  <Checkbox
                    id="wetlands_file"
                    name="mapType"
                    value="wetlands_file"
                    text={t("Wetlands")}
                    onChange={() => handleMapChange("wetlands_file")}
                    checked={selectedMapType === "wetlands_file"}
                  />
                </div>
              </div>
            </div>
            <div className={classes.submenu}>
              <u>{t("Adjacent land use")}</u>
              <div className={classes.subItems}>
                <div>
                  <Checkbox
                    id="croplands_file"
                    name="mapType"
                    value="croplands_file"
                    text={t("Croplands")}
                    onChange={() => handleMapChange("croplands_file")}
                    checked={selectedMapType === "croplands_file"}
                  />
                </div>
                <div>
                  <Checkbox
                    id="crops_file"
                    name="mapType"
                    value="crops_file"
                    text={t("Crops")}
                    onChange={() => handleMapChange("crops_file")}
                    checked={selectedMapType === "crops_file"}
                  />
                </div>
                <div>
                  <Checkbox
                    id="protected_file"
                    name="mapType"
                    value="protected_file"
                    text={t("Protected areas")}
                    onChange={() => handleMapChange("protected_file")}
                    checked={selectedMapType === "protected_file"}
                  />
                </div>
                <div>
                  <Checkbox
                    id="residential_file"
                    name="mapType"
                    value="residential_file"
                    text={t("Residential areas")}
                    onChange={() => handleMapChange("residential_file")}
                    checked={selectedMapType === "residential_file"}
                  />
                </div>
                <div>
                  <Checkbox
                    id="sub_urban_file"
                    name="mapType"
                    value="sub_urban_file"
                    text={t("Suburban areas")}
                    onChange={() => handleMapChange("sub_urban_file")}
                    checked={selectedMapType === "sub_urban_files"}
                  />
                </div>
                <div>
                  <Checkbox
                    id="roads_file"
                    name="mapType"
                    value="roads_file"
                    text={t("Roads")}
                    onChange={() => handleMapChange("roads_file")}
                    checked={selectedMapType === "roads_file"}
                  />
                </div>
              </div>
            </div>
          </DrawerWrapper>
        </div>

        {centerCoords ? (
          <MapContainer
            key={`${centerCoords[0]}-${centerCoords[1]}`} // Update key when centerCoords change
            className={classes.mapContainer}
            // style={{ height: "1000px", width: "80%", margin: '0 auto' }}
            zoomControl={false}
            center={[centerCoords[1], centerCoords[0]]} // Swap lat and lng
            zoom={5}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <GeoJSONMap
              key={JSON.stringify(geoJSONData)}
              geoJSONData={geoJSONData}
              center={centerCoords}
            />
          </MapContainer>
        ) : (
          <p>{t("Loading map")}...</p>
        )}
      </div>
    </>
  );
};

export default GeospatialData;

const DrawerWrapper = styled(Drawer) `
  & { 
    width: fit-content !important;
    height: 61rem !important;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    margin-top: 24.32rem; 
  }
`

const ButtonWrapper = styled(Button) `
  & {
    margin-top: -2rem; 
    margin: 1rem;
    background-color: #1c1e53d0 !important;
  }

  &:hover {
    background-color: #1c1e53 !important;
  }
`
