import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet";
import BreadcrumbRow from "../../components/BreadcrumbRow/BreadcrumbRow";
import { Col, Row, Space, Tabs } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import classes from "./environmentalData.module.css";
import marker from "../../img/marker.png";
import temperatureIcon from "../../img/meteorological-data/temperature.png";
import humidityIcon from "../../img/meteorological-data/humidity.png";
import windDirectionIcon from "../../img/meteorological-data/wind-direction.png";
import windSpeedIcon from "../../img/meteorological-data/wind-speed.png";
import uvIndex from "../../img/meteorological-data/uv-index.png";

import Map from "./Map";
import { fetchWeatherData } from "../../services/weatherService";
import FormControl from "@mui/material/FormControl";
import {
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import BarChart from "./components/BarChart/BarChart";
import PieChart from "./components/PieChart/PieChart";
import DataDisplayTable from "./components/DataDisplayTable/DataDisplayTable";
import DataSectionCard from "./components/DataSectionCard/DataSectionCard";
import pawIcon from "../../img/paw.png";
import habitatClassIcon from "../../img/habitatClassIcon.png";
import HabitatClass from "./components/HabitatClass/HabitatClass";
import SliderComponent from "../../components/SliderComponent/SliderComponent";
import { CalendarOutlined } from '@ant-design/icons'
import { getHabitatsData, getMapData, getSpeciesData, getTableData, searchTableData, selectHabitatsData, selectMapData, selectSpeciesData, selectTableData } from "../../redux/environmentalSlice";
import { useAppDispatch } from "../../redux/hooks";

interface WeatherDataProps {
  current: {
    temp: number;
    humidity: number;
    wind_speed: number;
    uvi: number;
    wind_deg: number;
  };
}

interface EnvironmentalDataProps {
  aqi: number;
  co: number;
  no2: number;
  o3: number;
  pm2_5: number;
  pm10: number;
  so2: number;
}

interface Species {
  id: string;
  name: string;
  group: string;
  populationType: string;
  lowBound: number;
  upperBound: number;
  unit: string;
  conservation: string;
  population: string;
}

const DigitalLibrary: React.FC = () => {
  const { t } = useTranslation();
  const tableData = useSelector(selectTableData)
  const mapData = useSelector(selectMapData)
  const speciesData = useSelector(selectSpeciesData)
  const habitatsData = useSelector(selectHabitatsData)

  console.log(tableData)

  const colors = ['#F4B678', '#8BC1F7', '#C9190B', '#7CC674', '#8481DD', '#F0AB00', '#06C']

  const dataForDisplayTable: Species[] = [
    {
      id: "A079",
      name: "Aegypius monachus",
      group: "Birds",
      populationType: "Concentration",
      lowBound: 3,
      upperBound: 3,
      unit: "individuals",
      conservation: "Good",
      population: "2% >= p > 0%",
    },
    {
      id: "A037",
      name: "Cygnus columbianus bewickii",
      group: "ABirds",
      populationType: "Concentration",
      lowBound: 3,
      upperBound: 3,
      unit: "individuals",
      conservation: "Good",
      population: "2% >= p > 0%",
    },
  ];

  // const yearData = ['2024', '2023', '2022', '2021']
  const yearData = ['2022']

  const [filteredData, setFilteredData] =
    useState<Species[]>(dataForDisplayTable);

  const [sortingOption, setSortingOption] = useState<keyof Species | null>(
    null
  ); // Correctly typed as keyof Species | null
  const [searchText, setSearchText] = useState(""); // State to hold search text
  const [chartData, setChartData] = useState([])
  const [speciesCategories, setSpeciesCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')

  // const [results, setResults] = useState<any[]>([]);
  const [selectedFeature, setSelectedFeature] = useState<string>(yearData[0]);

  const [environmentalData, setEnvironmentalData] = useState<
    EnvironmentalDataProps[]
  >([]);
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const [activeTab, setActiveTab] = useState<string>("1");

  const [selectedFeatureCoordinates, setSelectedFeatureCoordinates] = useState<{
    [key: string]: number[][];
  }>({});

  // console.log(selectedFeatureCoordinates)
  
  const [weatherData, setWeatherData] = useState<WeatherDataProps | null>(null);
  const dispatch = useAppDispatch()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    dispatch(getTableData({accessToken, activeTab}))
    dispatch(getMapData({accessToken, activeTab}))
    dispatch(getSpeciesData(accessToken))
    dispatch(getHabitatsData({accessToken, activeTab}))
  },[accessToken, activeTab])

  const onlyUnique = (value: any, index: any, array: any) => {
    return array.indexOf(value) === index;
  }
  
  useEffect(() => {
    if(mapData.features !== undefined){
      const speciesNames = mapData.features.map((feat: any) => {return feat.properties.Name})
      const uniqueNames = speciesNames.filter(onlyUnique)
      const coordinates = getCoordinatesForFeature(uniqueNames[0]);
  
      setSelectedCategory(uniqueNames[0])
      setSpeciesCategories(uniqueNames)
      setSelectedFeatureCoordinates(coordinates);
    }
  },[mapData])


  const handleSorting = (option: keyof Species | null) => {
    setSortingOption(option); // Set sorting option directly

    // apply sorting logic
    if (option === "name" || option === "group") {
      setFilteredData((prevData) => {
        return prevData.slice().sort((a, b) => {
          if (option === "name") {
            return a.name.localeCompare(b.name);
          } else if (option === "group") {
            return a.group.localeCompare(b.group);
          }
          return 0;
        });
      });
    }
  };

  const getCoordinatesForFeature = (
    featureName: string
  ): { [key: string]: number[][] } => {
    const { features } = mapData;
    const coordinates: { [key: string]: number[][] } = {};

    for (const feature of features) {
      if (feature.properties.Name === featureName) {
        if (!coordinates[featureName]) {
          coordinates[featureName] = [];
        }
        coordinates[featureName].push(feature.geometry.coordinates);
      }
    }

    return coordinates;
  };

  const getWindDirection = (degrees: number) => {
    const directions = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];
    const index = Math.round((degrees % 360) / 45);
    return directions[index % 8];
  };

  const handleFeatureSelect = (event: SelectChangeEvent<string>) => {
    const featureName = event.target.value as string;
    setSelectedCategory(featureName)

    // Get coordinates based on the selected feature and set to selectedFeatureCoordinates
    const coordinates = getCoordinatesForFeature(featureName);
    setSelectedFeatureCoordinates(coordinates);
  };

  const items = [
    {
      key: "1",
      label: `${t("Hirkan")}`,
    },
    {
      key: "2",
      label: `${t("Shirvan")}`,
    },
    {
      key: "3",
      label: `${t("Absheron")}`,
      disabled: true,
    },
    {
      key: "4",
      label: `${t("Ag-Gol")}`,
      disabled: true,
    },
    {
      key: "5",
      label: `${t("Altyaghach")}`,
      disabled: true,
    },
    {
      key: "6",
      label: `${t("Göygöl")}`,
      disabled: true,
    },
    {
      key: "7",
      label: `${t("Gizilaghaj")}`,
      disabled: true,
    },
    {
      key: "8",
      label: `${t("Samur-Yalama")}`,
      disabled: true,
    },
    {
      key: "9",
      label: `${t("Shahdag")}`,
      disabled: true,
    },
    {
      key: "10",
      label: `${t("Zangezur")}`,
      disabled: true,
    }
  ];

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  function getItemByKey(key: string) {
    return items.find((item) => item.key === key);
  }

  const handleSearch = (searchText: string) => {
    setSearchText(searchText); // Set search text to state
    dispatch(searchTableData({accessToken, activeTab, searchText}))
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        const environmentalDataResponse = await fetch(`https://fao-cms.etsn.az/api/parks/${activeTab}/air-quality-measurements/latest`, {
            headers,
          }
        );

        if (!environmentalDataResponse.ok) {
          throw new Error("Failed to fetch environmental data");
        }

        const environmentalDataRes = await environmentalDataResponse.json();
        setEnvironmentalData(environmentalDataRes);

        const latitude = 39.60506102137459;
        const longitude = 49.12437350375863;
        const weatherResponse = await fetchWeatherData(latitude, longitude);

        setWeatherData(weatherResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accessToken, activeTab]);

  useEffect(() => {
    if (speciesData.length) {
      const selectedSpeciesData = speciesData[parseInt(activeTab) - 1]?.years[selectedFeature]
      const newChartData = selectedSpeciesData?.map((data: any, index: number) => {
        return { type: `${t(data.name)}`, species: data.count, color: colors[index] }
      })

      setChartData(newChartData)
    }
  }, [activeTab, selectedFeature, speciesData]);

  return (
    <>
      <HelmetComponent
        title={t("MENR | Environmental Data") || undefined}
        description={t("Environmental Data") || undefined}
      />

      <BreadcrumbRow title={"Environmental Data"} />

      <div className={classes.tabContent}>
        <div className={classes.tabContentInner}>
          <h1 style={{padding: '1rem'}}>{t("Environmental Data")}</h1>
          
          <Tabs
            activeKey={activeTab}
            items={items}
            onChange={onTabChange}
            className={classes.tabs}
          />

          <Row>
            <div className={classes.cardsContainer}>
              <Col lg={16} xs={24}>
                <div className={classes.card}>
                  <Space wrap className={classes.selects}>
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="select-label"
                        id="select"
                        value={selectedFeature}
                        className={classes.select}
                        startAdornment={
                          <InputAdornment position="start">
                            <IconButton edge="start" disabled>
                              <InputLabel id="select-label">
                                <CalendarOutlined style={{fontSize: '1rem'}}/>
                              </InputLabel>
                            </IconButton>
                          </InputAdornment>
                        }
                      >
                        {yearData.map(year => <MenuItem key={`dropdown-${year}`} value={t(year)}>{t(year)}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Space>
                  
                  <BarChart data={chartData} />

                  <span className={classes.activePark}>
                    <div className={classes.square} />
                    {getItemByKey(activeTab)?.label}
                  </span>
                </div>
              </Col>

              <Col xs={24} sm={{span: 20, offset: 2}} md={{span: 16, offset: 4}} lg={7}>
                <div className={classes.card}>
                  <PieChart data={chartData} year={yearData[0]} />
                </div>
              </Col>
            </div>
          </Row>

          <br />

          <DataSectionCard
            icon={pawIcon}
            title={t("Species")}
            showFilters={false}
            isChildrenVisible={false}
            toggleChildrenVisibility={() => {}}
            handleFiltering={() => {}}
            handleSorting={(option) => handleSorting(option)}
            handleSearch={handleSearch}
          >
            <DataDisplayTable
              data={tableData}
              handleSorting={handleSorting} // Pass handleSorting function
              searchText={searchText}
            />
          </DataSectionCard>

          <br />
          
          <div className={classes.mapContainer}>
            <Select
              labelId="map-select-label"
              id="map-select"
              value={selectedCategory}
              onChange={handleFeatureSelect}
              className={classes.select}
            >
              {speciesCategories.map(category => <MenuItem key={`dropdown-${category}`} value={category}>{category}</MenuItem>)}
            </Select>

            <Map featureCoordinates={selectedFeatureCoordinates} />
          </div>

          <br />

          <div className={classes.card}>
            <h4>{t("Environmental Data")}</h4>

            <div className={classes.flex}>
              <img src={marker} alt="Place marker" style={{ maxWidth: 24 }} />
              <p>{activeTab === '1' ? `${t("Hirkan")}` : `${t("Shirvan")}`} {t("National Park")}</p>
            </div>

            <div className={classes.divider} />

            <Row style={{gap: '1rem'}}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <div className={classes.flex}>
                  <img
                    src={temperatureIcon}
                    alt="Air Quality Index"
                    style={{ maxWidth: 22, maxHeight: 22 }}
                  />
                  <div>
                    <h4>{t("Air Quality Index")}</h4>
                    <p>
                      {environmentalData.length > 0
                        ? environmentalData[0].aqi
                        : "-"}
                    </p>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <div className={classes.flex}>
                  <img
                    src={temperatureIcon}
                    alt="Sulfur dioxide"
                    style={{ maxWidth: 22, maxHeight: 22 }}
                  />
                  <div>
                    <h4>{t("Sulfur dioxide")}</h4>
                    <p>
                      {environmentalData.length > 0
                        ? environmentalData[0].so2
                        : "-"}{" "}
                      μg/m³
                    </p>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <div className={classes.flex}>
                  <img
                    src={temperatureIcon}
                    alt="Ozone"
                    style={{ maxWidth: 22, maxHeight: 22 }}
                  />
                  <div>
                    <h4>{t("Ozone")}</h4>
                    <p>
                      {environmentalData.length > 0
                        ? environmentalData[0].o3
                        : "-"}{" "}
                      μg/m³
                    </p>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <div className={classes.flex}>
                  <img
                    src={temperatureIcon}
                    alt="Carbon monoxide"
                    style={{ maxWidth: 22, maxHeight: 22 }}
                  />
                  <div>
                    <h4>{t("Carbon monoxide")}</h4>
                    <p>
                      {environmentalData.length > 0
                        ? environmentalData[0].co
                        : "-"}{" "}
                      μg/m³
                    </p>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <div className={classes.flex}>
                  <img
                    src={temperatureIcon}
                    alt="PM10 dust particles"
                    style={{ maxWidth: 22, maxHeight: 22 }}
                  />
                  <div>
                    <h4>{t("PM10 dust particles")}</h4>
                    <p>
                      {environmentalData.length > 0
                        ? environmentalData[0].pm10
                        : "-"}{" "}
                      μg/m³{" "}
                    </p>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <div className={classes.flex}>
                  <img
                    src={temperatureIcon}
                    alt="Dust particles PM2.5"
                    style={{ maxWidth: 22, maxHeight: 22 }}
                  />
                  <div>
                    <h4>{t("Dust particles PM2.5")}</h4>
                    <p>
                      {environmentalData.length > 0
                        ? environmentalData[0].pm2_5
                        : "-"}{" "}
                      μg/m³
                    </p>
                  </div>
                </div>
              </Col>
              
              <Col xs={24} sm={12} md={8} lg={6}>
                <div className={classes.flex}>
                  <img
                    src={temperatureIcon}
                    alt="Nitrogen dioxide"
                    style={{ maxWidth: 22, maxHeight: 22 }}
                  />
                  <div>
                    <h4>{t("Nitrogen dioxide")}</h4>
                    <p>
                      {environmentalData.length > 0
                        ? environmentalData[0].no2
                        : "-"}{" "}
                      μg/m³
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <br />

          <div className={classes.card}>
            <h4>{t("Meteorological data")}</h4>

            <div className={classes.flex}>
              <img src={marker} alt="Place marker" style={{ maxWidth: 24 }} />
              <p>{activeTab === '1' ? `${t("Hirkan")}` : `${t("Shirvan")}`} {t("National Park")}</p>
            </div>

            <div className={classes.divider} />

            <Row style={{gap: '0.5rem'}}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <div className={classes.flex}>
                  <img
                    src={temperatureIcon}
                    alt="Temperature"
                    style={{ maxWidth: 22, maxHeight: 22 }}
                  />
                  <div>
                    <h4>{t("Temperature")}</h4>
                    <p>{weatherData?.current.temp} °C</p>
                  </div>
                </div>
                <br />
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <div className={classes.flex}>
                  <img
                    src={humidityIcon}
                    alt="Humidity"
                    style={{ maxWidth: 22, maxHeight: 22 }}
                  />
                  <div>
                    <h4>{t("Humidity")}</h4>
                    <p>{weatherData?.current.humidity} %</p>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <div className={classes.flex}>
                  <img
                    src={windDirectionIcon}
                    alt="Wind direction"
                    style={{ maxWidth: 22, maxHeight: 22 }}
                  />
                  <div>
                    <h4>{t("Wind direction")}</h4>
                    <p>{getWindDirection(weatherData?.current.wind_deg!)}</p>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <div className={classes.flex}>
                  <img
                    src={windSpeedIcon}
                    alt="Wind speed"
                    style={{ maxWidth: 22, maxHeight: 22 }}
                  />
                  <div>
                    <h4>{t("Wind speed")}</h4>
                    <p>{weatherData?.current.wind_speed} km/h</p>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <div className={classes.flex}>
                  <img
                    src={uvIndex}
                    alt="UV index"
                    style={{ maxWidth: 22, maxHeight: 22 }}
                  />
                  <div>
                    <h4>{t("UV index")}</h4>
                    <p>{weatherData?.current.uvi}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <br />
        </div>
      </div>
    </>
  );
};

export default DigitalLibrary;
