import React, { useEffect } from 'react'
import classes from './hirkan.module.css'
import HelmetComponent from '../../components/Helmet'
import { useTranslation } from 'react-i18next';
import { Button, Col, Image, Row } from 'antd';
import video1 from '../../img/video1.png'
import video2 from '../../img/video2.png'

const Hirkan = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },[])

    return (
        <>
            <HelmetComponent
                title={t("MENR | Hirkan") || undefined}
                description={t("SEO Home description") || undefined}
            />
            <div className={classes.headerImage}>
                <h2 className={classes.title}>{t("Hirkan")} {t("National Park")}</h2>
            </div>

            <div className={classes.textContainer}>
                <h2 className={classes.subtitle}>{t("About the park")}</h2>
                <p className={classes.text}>{t("Text about Hirkan")}</p>
            </div>

            <div className={classes.videoContainer}>
                <div className={classes.videoContent}>
                    <h2 className={classes.videoTitle}>{t("Featured Videos")}</h2>
                    <Row gutter={50}>
                        <Col className="gutter-row" lg={12} xs={24} style={{paddingBottom: '3rem'}}>
                            <Image className={classes.videoImage} src={video1} preview={false} onClick={() => window.open("https://www.youtube.com/watch?v=VH2cldzXGDc", "_blank")}/>
                        </Col>
                        <Col className="gutter-row" lg={12} xs={24} style={{paddingBottom: '3rem'}}>
                            <Image className={classes.videoImage} src={video2} preview={false} onClick={() => window.open("https://www.youtube.com/watch?v=nDsqU33XtQE", "_blank")}/>
                        </Col>
                    </Row>
                </div>
            </div>

            <Row style={{justifyContent: 'center'}}> 
                <Col>
                    <Button className={classes.button} onClick={() => window.open("https://nationalparks.az/parklar/hirkan-milli-parki", "_blank")}>{t("Park Page")}</Button>
                </Col>
            </Row>
        </>
    )
}

export default Hirkan