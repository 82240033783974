import { Input } from "antd";
import React, { useState, useEffect } from "react";
import classes from "./SearchBar.module.css";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

export interface DataType {
  name: string;
  created: string;
  type: string;
  size: string;
  file: string;
  id: number;
}
interface SearchBarProps {
  setSearchResults: React.Dispatch<React.SetStateAction<any>>;
  parkId: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  setSearchResults,
  parkId,
}) => {
  const { t } = useTranslation();
  const [input, setInput] = useState<string>("");
  const currentLanguageCode = cookies.get("i18next") || "el";
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);

  const performSearch = async (searchValue: string) => {
    try {
      const settings = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(
        // `https://fao-cms.etsn.az/api/parks/${parkId}/digital-library?search=${searchValue}`,
        `https://fao-cms.etsn.az/api/digital-library?park_id=${parkId}&search=${searchValue}`,
        settings
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error("Error fetching and processing data:", error);
    }
  };

  useEffect(() => {
    
    performSearch(input);
  }, [input, parkId, accessToken]);

  const handleChange = (value: string) => {
    setInput(value);
  };

  return (
    <div className={classes.inputWrapper}>
      <Input
        className={classes.input}
        placeholder={t("Search") + " . . ."}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};
