import React from "react";
import SectionHead from "../SectionHead/SectionHead";
import { SectionHeadProps, Species } from "../../../../types/types";
import classes from "./DataSectionCard.module.css";

interface DataSectionCardProps {
  children: React.ReactNode;
  icon: string; // Add icon prop
  title: string;
  showFilters: boolean;
  isChildrenVisible: boolean;
  toggleChildrenVisibility: () => void;
  // sortingOptions: (keyof Species)[];
  handleFiltering: () => void;
  handleSorting: (option: keyof Species | null) => void;
  park?: string;
  handleSearch: (inputText: string) => void;
  backgroundColor?: string;
}

const DataSectionCard: React.FC<DataSectionCardProps> = ({
  children,
  icon,
  title,
  isChildrenVisible,
  toggleChildrenVisibility,
  showFilters,
  // sortingOptions,
  handleFiltering,
  handleSorting,
  handleSearch,
  backgroundColor
}) => {
  const clonedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { handleSearch } as SectionHeadProps); // Explicitly define props object
    }
    return child;
  });

  return (
    <div className={classes.dataSectionCard} style={{backgroundColor: backgroundColor, borderRadius: '10px'}}>
      <SectionHead
        icon={icon}
        title={title}
        isChildrenVisible={isChildrenVisible}
        toggleChildrenVisibility={toggleChildrenVisibility}
        showFilters={showFilters}
        // sortingOptions={sortingOptions}
        handleSorting={handleSorting}
        handleSearch={handleSearch} // Pass handleSearch prop
      />
      {clonedChildren}
    </div>
  );
};

export default DataSectionCard;
