import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import classes from "./BreadcrumbRow.module.css";
import { Col, Row, Breadcrumb } from "antd";
import { useTranslation } from "react-i18next";
import { fetchUserProfile } from "../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const breadcrumbColors: any = {
  'Environmental Data': '#FDEC06',
  'Digital Library': '#81AE41',
  'Geospatial Data': '#B08274',
  'Park Management': '#A5D7F1'
}

const BreadcrumbRow = ({ parent, title }: any) => {
  const { t } = useTranslation();
  const [userProfile, setUserProfile] = useState<any>(null);
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);

  useMemo(async () => {
    if (accessToken) {
      const fetchUserData = async () => {
        try {
          const profile = await fetchUserProfile(accessToken);
          setUserProfile(profile);
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      };

      fetchUserData();
    }
  }, [accessToken]);

  return (
    <>
      <div className={classes.breadcrumbContainer} style={{backgroundColor: title ? breadcrumbColors[title] : breadcrumbColors[parent]}}>
        <Row justify="center">
          <div className={classes.width}>
            <Col xs={21} sm={21} md={21} lg={21}>
              <Breadcrumb className={classes.Breadcrumb} separator=">">
                <Breadcrumb.Item>
                  <Link to="/">{t("Home")}</Link>
                </Breadcrumb.Item>

                {parent ? (
                  <Breadcrumb.Item>
                    <Link to="/digital-library">{t(parent)}</Link>
                  </Breadcrumb.Item>
                ) : (
                  ""
                )}

                <Breadcrumb.Item>
                  <p>{t(title)}</p>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </div>
        </Row>
      </div>
    </>
  );
};

export default BreadcrumbRow;
