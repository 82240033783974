import { useState } from "react";
import { Col, Row } from "antd";
import classes from "./FAQItem.module.css";
import closeIcon from "../../img/close-icon.png";
import openIcon from "../../img/open-icon.png";

const FAQItem: React.FC<{ id: string; question: string; answer: string }> = ({
  id,
  question,
  answer,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleItem = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes.faqItem}>
      <Row>
        <Col lg={3}>
          <span className={classes.number}>{id}</span>
        </Col>
        <Col lg={21}>
          <div className={classes.question} onClick={toggleItem}>
            {question}{" "}
            <span className={classes.toggle}>
              {isOpen ? (
                <img src={closeIcon} alt="close icon" />
              ) : (
                <img src={openIcon} alt="open icon" />
              )}
            </span>
          </div>
          {isOpen && <div className={classes.answer}>{answer}</div>}
        </Col>
      </Row>
    </div>
  );
};

export default FAQItem;
