import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
  tableData: [],
  mapData: {},
  speciesData: [],
  habitatsData: []
}

const environmentalSlice = createSlice({
    name: 'environmental',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(getTableData.fulfilled, (state, action) => {
        const formatedData = action.payload.map((item: any) => {
          return {
            key: item.id,
            id: item?.code,
            name: item?.title,
            group: item?.species_category[0].name,
            populationType: item?.population_type[0].description,
            lowBound: item?.lower_bound,
            upperBound: item?.upper_bound,
            unit: item?.population_unit[0].description,
            conservation: item?.conservation_degree[0].description,
            // should be changed
            population: item?.population_density[0].description.replaceAll("&gt;", ">"),
          }
        })
        state.tableData = formatedData
      })
      builder.addCase(getTableData.rejected, (action) => {
        console.log("Error getting table data", action)
      })
      builder.addCase(searchTableData.fulfilled, (state, action) => {
        const formatedData = action.payload.map((item: any) => {
          return {
            key: item.id,
            id: item?.code,
            name: item?.title,
            group: item?.species_category[0].name,
            populationType: item?.population_type[0].description,
            lowBound: item?.lower_bound,
            upperBound: item?.upper_bound,
            unit: item?.population_unit[0].description,
            conservation: item?.conservation_degree[0].description,
            // should be changed
            population: item?.population_density[0].description.replaceAll("&gt;", ">"),
          }
        })
        state.tableData = formatedData
      })
      builder.addCase(searchTableData.rejected, (action) => {
        console.log("Error searching table data", action)
      })
      builder.addCase(getMapData.fulfilled, (state, action) => {
        state.mapData = action.payload
      })
      builder.addCase(getMapData.rejected, (action) => {
        console.log("Error getting map data:", action)
      })
      builder.addCase(getSpeciesData.fulfilled, (state, action) => {
        state.speciesData = action.payload
      })
      builder.addCase(getSpeciesData.rejected, (action) => {
        console.log("Error getting species data:", action)
      })
      builder.addCase(getHabitatsData.fulfilled, (state, action) => {
        state.habitatsData = action.payload
      })
      builder.addCase(getHabitatsData.rejected, (action) => {
        console.log("Error getting habitats data:", action)
      })
    }
})

export const {} = environmentalSlice.actions

export default environmentalSlice.reducer

export const selectTableData = (state: any) => state.environmental.tableData 
export const selectMapData = (state: any) => state.environmental.mapData
export const selectSpeciesData = (state: any) => state.environmental.speciesData
export const selectHabitatsData = (state: any) => state.environmental.habitatsData

export const getTableData = createAsyncThunk(
  'environmental/getTableData',
  async ({accessToken, activeTab}: any) => {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    
    const response = await fetch(`https://fao-cms.etsn.az/api/species?park_id=${activeTab}`, {
        headers,
      }
    );

    return await response.json()
  },
)

export const searchTableData = createAsyncThunk(
  'environmental/searchTableData',
  async ({accessToken, activeTab, searchText}: any) => {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    
    const response = await fetch(`https://fao-cms.etsn.az/api/species?park_id=${activeTab}&search=${searchText}`, {
        headers,
      }
    );

    return await response.json()
  },
)

export const getMapData = createAsyncThunk(
  'environmental/getMapData',
  async ({accessToken, activeTab}: any) => {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    
    const response = await fetch(`https://fao-cms.etsn.az/sites/default/files/parks/files/${activeTab === '1' ? 'hirkan' : 'shirvan'}-flora-fauna.geojson`, {
        headers,
      }
    );

    return await response.json()
  },
)

export const getSpeciesData = createAsyncThunk(
  'environmental/getSpeciesData',
  async (accessToken: any) => {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    
    const response = await fetch(`https://fao-cms.etsn.az/api/species/counts`, {
        headers,
      }
    );

    return await response.json()
  },
)

export const getHabitatsData = createAsyncThunk(
  'environmental/getHabitatsData',
  async ({accessToken, activeTab}: any) => {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    
    const response = await fetch(`https://fao-cms.etsn.az/api/habitats?park_id=${activeTab}`, {
        headers,
      }
    );

    return await response.json()
  },
)
