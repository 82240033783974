import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { links } from "./Mylinks";
import styles from './NavLinks.module.css'
import { DownOutlined, UpOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

const NavLinks: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [heading, setHeading] = useState<string>("");
  const [subHeading, setSubHeading] = useState<string>("");
  const [openSublink, setOpenSublink] = useState<string>("");
  const [topPosition, setTopPosition] = useState<number>();

    

  const handleHeadHover = (element: HTMLElement | null) => {
    setOpenSublink(element ? element.getAttribute('data-linkname') ?? "" : "");

    if (element) {
      const rect = element.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const newTopPosition = rect.top + scrollTop - 88;
      setTopPosition(newTopPosition);
    }
  };

  return (
    <>
      {links.map((link, linkIndex) => (
        <div key={linkIndex}>
          <div className={styles.linkIndex}>
            <h1
              className={styles.menuItem}
              onClick={() => {
                if (link.link) {
                  navigate(link.link)
                }
                if (heading)
                  setHeading("");
                else {
                  setHeading(link.name);
                  setSubHeading("");
                }
              }}
              onMouseEnter={() => {
                setHeading(link.name);
                setSubHeading("");
              }}
              onMouseLeave={() => {
                setHeading("");
              }}
            >
              {t(link.name)}
              <span className={styles.icon} >
                {link.sublinks ? heading === link.name ? <UpOutlined /> : <DownOutlined /> : ''}
              </span>

              <div className={styles.sublinkContainer}>
                <div className={styles.sublink} >
                  <span >
                    {link.sublinks ? <DownOutlined /> : ''}
                  </span>
                </div>
              </div>
              
            </h1>
            {link.submenu && link.name && (
              <div>
                <div className={styles.subMenu}>
                  <div className={styles.subItems}>
                    {link.sublinks.map((mysublinks, sublinkIndex) => (
                      <div key={sublinkIndex}>
                        <div
                          className={`${styles.subMenuItem} elementTop`}
                          onClick={() => { mysublinks.link && navigate(mysublinks.link) }}
                          onMouseEnter={(e) => {
                            handleHeadHover(e.currentTarget);
                          }}
                          onMouseLeave={() => {
                            handleHeadHover(null);
                          }}
                          data-linkname={mysublinks.Head}
                        >
                          <h1>{t(mysublinks.Head)}</h1>
                          <span>{mysublinks.sublink[0] ? '>' : ''}</span>
                        </div>

                        <div>
                          <div className={styles.subSubMenu} style={{
                            top: topPosition,
                            display: openSublink === mysublinks.Head ? 'block' : ''
                          }}>
                            {mysublinks.sublink.map((slink, slinkIndex) => (
                              <li
                                key={slinkIndex}
                                className={styles.subSubMenuItem}
                              >
                                {/* <Link to={slink.link}>
                                  {slink.name}
                                </Link> */}
                              </li>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          
          {/* Mobile menus */}
          <div
            className={`
            ${heading === link.name ? styles.subMenuMHidden : styles.subMenuMobileHidden}
          `}
            style={{ marginLeft: 12 }}
          >
            {/* sublinks */}
            {link.sublinks && link.sublinks.map((slinks, slinksIndex) => (
              <div key={slinksIndex}>
                <div>
                  <h1
                    className={styles.subMenuItemMobile}
                    onClick={() =>
                      subHeading !== slinks.Head
                        ? setSubHeading(slinks.Head)
                        : setSubHeading("")
                    }
                  >
                    {slinks.Head}
                    <span>
                      {slinks.sublink.length ? subHeading === slinks.Head
                        ? <UpOutlined />
                        : <DownOutlined /> : ''
                      }
                    </span>
                  </h1>
                  <div className={`${styles.subSubMenuMobile} ${subHeading === slinks.Head ? styles.subSubMenuMHidden : styles.subSubMenuMobileHidden}`}
                  >
                    {slinks.sublink.map((slink: any, slinkIndex) => (
                      <li key={slinkIndex}>
                        <Link to={slink.link}>{slink.name}</Link>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div >
      ))}
    </>
  );
};

export default NavLinks;