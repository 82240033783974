export const links = [
  // {
  //   name: "Home",
  //   link: "/",
  // },
  {
    name: "National Parks",
    submenu: true,
    sublinks: [
      {
        Head: "Shirvan",
        link: "/shirvan",
        sublink: [
          // { name: "Shirvan 1", link: "/" },
          // { name: "Shirvan 2", link: "/" },
          // { name: "Shirvan 3", link: "/" },
          // { name: "Shirvan 4", link: "/" },
          // { name: "Shirvan 5", link: "/" },
        ],
      },
      {
        Head: "Hirkan",
        link: "/hirkan",
        sublink: [
          // { name: "Hirkan 1", link: "/" },
          // { name: "Hirkan 2", link: "/" }
        ],
      },
    ],
  },
  {
    name: "Modules",
    submenu: true,
    sublinks: [
      {
        Head: "Environmental Data",
        link: "/environmental-data",
        sublink: [
          // { name: "Env Data 1", link: "/" },
          // { name: "Env Data 2", link: "/" },
          // { name: "Env Data 3", link: "/" },
          // { name: "Env Data 4", link: "/" },
          // { name: "Env Data 5", link: "/" },
        ],
      },
      {
        Head: "Digital Library",
        link: "digital-library",
        sublink: [
          // { name: "Digital File 1", link: "/" },
          // { name: "Digital File 2", link: "/" },
        ],
      },
      {
        Head: "Geospatial Data",
        link: "/geospatial-data",
        sublink: [
          // { name: "Page 1", link: "/" },
          // { name: "Page 2", link: "/" },
        ],
      },

      {
        Head: "Park Management",
        link: "/park-management",
        sublink: [
          // { name: "Page 1", link: "/" },
          // { name: "Page 2", link: "/" },
        ],
      },
    ],
  },
  {
    name: "FAQ",
    link: "/faq",
  },
];
