import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../img/logo.png";
import NavLinks from "./NavLinks";
import LanguageSwitcher from "./LanguageSwitcher";
import styles from "./Navbar.module.css";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/store";
import config, { fetchUserProfile } from "../../config";
import { Link, useLocation } from "react-router-dom";
import { login } from "../../redux/authSlice";
import { logout } from "../../redux/authSlice";
import { setAccessToken } from "../../redux/authSlice";
import Cookies from "js-cookie";
import Avatar from "../Avatar/Avatar";

const Navbar = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code") || "";
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(false)
  }, [location.pathname])

  useEffect(() => {
    if(!accessToken) {
      dispatch(logout());
    }
  },[accessToken])

  useEffect(() => {
    if (code) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${config.baseUrl}/oauth/token`, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
              grant_type: "authorization_code",
              client_id: config.clientId,
              client_secret: config.clientSecret,
              redirect_uri: config.redirectUri,
              scope: "users_administrator",
              code,
            }),
          });

          const data = await response.json();
          const accessToken = data.access_token;
          dispatch(setAccessToken(accessToken));
          if (accessToken) {
            dispatch(login(accessToken));

            // const userProfile = await fetchUserProfile(accessToken)
          } else {
            console.error("Access token is undefined");
          }
        } catch (error) {
          console.error("OAuth callback error:", error);
          throw new Error("Failed to complete OAuth process");
        }
      };

      fetchData();
    }
  }, [code, dispatch]);

  const handleLogout = async () => {
    try {
      dispatch(setAccessToken(null));
      dispatch(logout());
      // window.location.href = `${config.baseUrl}/user/logout?destination=http://localhost:3000/`;
      window.location.href = `${config.baseUrl}/user/logout?destination=https://menr.etsn.az/`;

      Cookies.remove("accessToken");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleLogin = () => {
    const { clientId, redirectUri } = config;
    const authEndpoint = `${config.baseUrl}/oauth/authorize`;

    window.location.href = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=users_administrator&response_type=code`;
  };

  return (
    <nav style={{backgroundColor: '#1C1E53'}}>
      <div className={styles.navFlex}>
        <div className={styles.logoContainer}>

          {/* menr logo */}
          <Link to={'/'}>
            <img src={Logo} alt="logo" className="logo" />
            <div style={{display: 'inline-grid', verticalAlign: 'top'}}>
              <span className={styles.logoSpan}>{t("MENR")}</span>
              <span className={styles.logoSubtext}>{t("Ministry of Ecology and Natural Resources")}</span>
            </div>
          </Link>
        </div>

        {/* burger icon */}
        <div className={styles.btnContainer} onClick={() => setOpen(!open)}>
          {open ? <CloseOutlined /> : <MenuOutlined />}
        </div>

        {/* nav links */}
        <ul className={styles.menu}>
          <NavLinks />
        </ul>

        {/* profile avatar */}
        <div className={styles.btnsContainer}>
          <Avatar />
            <LanguageSwitcher />
            {isAuthenticated ? (
              <button className={styles.btn} onClick={handleLogout}>{t("Logout")}</button>
            ) : (
              <button className={styles.btn} onClick={handleLogin}>{t("Login")}</button>
            )}
        </div>

        {/* Mobile nav */}
        <ul
          className={styles.menuMobile}
          style={{
            right: open ? "0" : "-105%",
          }}
        >
          <div className={styles.profileContainerMobile}>
            <Avatar />
            <LanguageSwitcher />
          </div>

          <NavLinks />

          <div className={styles.btnsContainerMobile}>
            {isAuthenticated ? (
              <button onClick={handleLogout}>{t("Logout")}</button>
            ) : (
              <button onClick={handleLogin}>{t("Login")}</button>
            )}
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
