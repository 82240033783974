import { Col, Row } from "antd";
import FAQItem from "../../components/FAQItem/FAQItem";
import classes from "./FAQ.module.css";
import { useTranslation } from "react-i18next";

const FAQ: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={classes.faq}>
      <div className={classes.container}>
        <Row>
          <Col lg={9}>
            <h4>{t("Frequently Asked Questions")}</h4>
            <p>{t("Still confused or unsure?")}</p>
            <p>
              {t("Contact us at dms_support@eco.gov.az or call +0000 111 222 333")}
            </p>
          </Col>
          <Col lg={15}>
            <FAQItem
              id="01"
              question={t("Question 1")}
              answer={t("Answer 1")}
            />
            <FAQItem
              id="02"
              question={t("Question 2")}
              answer={t("Answer 2")}
            />
            <FAQItem
              id="03"
              question={t("Question 3")}
              answer={t("Answer 3")}
            />
            <FAQItem
              id="04"
              question={t("Question 4")}
              answer={t("Answer 4")}
            />
            <FAQItem
              id="05"
              question={t("Question 5")}
              answer={t("Answer 5")}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FAQ;
