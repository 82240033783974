import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
    fileData: {}
}

const digitalLibrartSlice = createSlice({
    name: 'digitalLibrary',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFileData.fulfilled, (state, action) => {
            state.fileData = action.payload[0]
        })
        builder.addCase(getFileData.rejected, (action) => {
            console.log("Error fetching file data", action)
        })
    }
})

export const {} = digitalLibrartSlice.actions

export default digitalLibrartSlice.reducer

export const selectFileData = (state: any) => state.digitalLibrary.fileData

export const getFileData = createAsyncThunk(
    'digitalLibrary/getFileData',
    async ({accessToken, fileID}: any) => {
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        const response = await fetch(`https://fao-cms.etsn.az/api/digital-library/${fileID}` , { headers })
    
        return await response.json()
    }
)