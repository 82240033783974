// src/components/Navbar/Logo.tsx
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logo.png";
import classes from "./Logo.module.css";
import { useTranslation } from "react-i18next";

const Logo: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Link to="/" className="logo-link">
      <div className={classes.logoContainer}>
        <img src={logo} alt="logo" />
        <div style={{display: 'inline-grid', verticalAlign: 'top'}}>
            <span className={classes.logoSpan} style={{width: '100px'}}>{t("MENR")}</span>
            <span className={classes.logoSubtext} style={{width: '180px'}}>{t("Ministry of Ecology and Natural Resources")}</span>
          </div>
      </div>
    </Link>
  );
};

export default Logo;
