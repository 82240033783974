import React, { Children, useEffect, useState } from "react";
import styles from './DataDisplayTable.module.css'
import { Button, Collapse, Dropdown, MenuProps, Popover, Table } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { Species } from "../../../../types/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface DataDisplayTableProps {
  data: Species[];
  handleSorting: (option: keyof Species | null) => void;
  searchText: string; // Add searchText prop
}

const DataDisplayTable: React.FC<DataDisplayTableProps> = ({
  data,
  handleSorting,
  searchText,
}) => {
  const { t } = useTranslation()

  const renderBound = (text: string, record: Species) => (
    <span>{`${record.lowBound} - ${record.upperBound}`}</span>
  );

  const renderGroup = (text: string, record: Species) => (
    <span>{t(text)}</span>
  );

  const renderItems = (item: any) => {
    return {
      key: item.id,
      label: item.name,
      children: (
        <div className={styles.collapsedContainer}>
          <div style={{fontWeight: '500', color: 'black'}}>
            <p>{t('POPULATION TYPE')}: </p>
            <p>{t('LOWER-UPPER BOUND')}: </p>
            <p>{t('COUNTING UNIT')}: </p>
            <p>{t('CONSERVATION')}: </p>
            <p>{t('POPULATION')}: </p>
          </div>
          <div style={{marginLeft: '1rem'}}>
            <p>{item.populationType}</p>
            <p>{item.lowBound} - {item.upperBound}</p>
            <p>{item.unit}</p>
            <p>{item.conservation}</p>
            <p>{item.population}</p>
          </div>
        </div>
      )
    }
  }
  
  const renderDropdown = (text: string, record: Species) => (
    <Collapse className={styles.collapseContainer} items={[renderItems(record)]} />
  );

  const columnsMobile = [
    {
      title: `${t("SPECIES NAME")}`,
      dataIndex: "name",
      key: "name",
      render: renderDropdown
    },
    { 
      title: `${t("GROUP")}`, 
      dataIndex: "group", 
      key: "group", 
      // render: renderGroup 
    },
  ];

  const columnsDesktop = [
    {
      title: `${t("SPECIES NAME")}`,
      dataIndex: "name",
      key: "name",
    },
    { 
      title: `${t("GROUP")}`, 
      dataIndex: "group", 
      key: "group", 
      render: renderGroup 
    },
    {
      title: `${t("POPULATION TYPE")}`,
      dataIndex: "populationType",
      key: "populationType",
      render: renderGroup
    },
    {
      title: `${t("LOWER-UPPER BOUND")}`,
      dataIndex: "bound",
      key: "bound",
      render: renderBound,
    },
    { 
      title: `${t("COUNTING UNIT")}`, 
      dataIndex: "unit", 
      key: "unit", 
      render: renderGroup  
    },
    { title: `${t("CONSERVATION")}`, dataIndex: "conservation", key: "conservation", render: renderGroup },
    { title: `${t("POPULATION")}`, dataIndex: "population", key: "population", render: renderGroup },
  ]

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    //Handle Responsiveness  
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
        
    handleResize();

    // Bind the event listener
    window.addEventListener('resize', handleResize)

    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getColumns = () => {
    if(width > 1020) {
      return columnsDesktop
    } else {
      return columnsMobile
    }
  }

  const showInfo = (data: any) => {
    return (
      <Popover content={data} title="Title">
        <Button type="primary">Hover me</Button>
      </Popover>
    )
  }

  const columns = getColumns()

  return (
    // @ts-ignore
      <TableWrapper
        // className={styles.table}
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => showInfo(record)
          };
        }}
        dataSource={data.filter((item) => {
            item.name.toLowerCase().includes(searchText.toLowerCase())
            return `${t(item.name)}`
          }
        )}
        pagination={{ pageSize: 10, showSizeChanger: false, showQuickJumper: false }}
        scroll={{ y: 400 }}
        onChange={(
          pagination,
          filters,
          sorter: SorterResult<Species> | SorterResult<Species>[]
        ) => {
          if (Array.isArray(sorter)) {
            // Handle multi-column sorting if needed
          } else if (sorter && "field" in sorter) {
            if (sorter.field === "group") {
              // Handle sorting by "group" column
              handleSorting(sorter.order === "ascend" ? "group" : null);
            } else {
              handleSorting(null); // Reset sorting option for other columns
            }
          }
        }}
      />
  );
};


export default DataDisplayTable;

const TableWrapper = styled(Table) `
  .ant-collapse .ant-collapse-content {
    background-color: transparent !important;
  }

  .ant-table-row >.ant-table-cell-row-hover {
    background: none !important
  }

  .ant-collapse-header-text {
    color: #282938 !important
  }

  .ant-table-pagination-right {
    justify-content: center !important
  }
`