import React, { useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import { LatLngBounds, GeoJSON as LeafletGeoJSON } from "leaflet";

interface GeoJSONMapProps {
  geoJSONData: any;
  center: [number, number];
}

const GeoJSONMap: React.FC<GeoJSONMapProps> = ({ geoJSONData, center }) => {
  // const map = useMap();

  // useEffect(() => {
  //   if (geoJSONData) {
  //     const bounds = getBoundsFromGeoJSON(geoJSONData);
  //     if (bounds) {
  //       map.fitBounds(bounds);
  //     }
  //   }
  // }, [geoJSONData, map]);

  // const getBoundsFromGeoJSON = (data: any): LatLngBounds | null => {
  //   if (data.features && data.features.length > 0) {
  //     const coordinates = data.features[0].geometry.coordinates[0];
  //     const bounds = coordinates.map((coord: [number, number]) => {
  //       return [coord[1], coord[0]];
  //     });
  //     return new LatLngBounds(bounds);
  //   }
  //   return null;
  // };
  console.log("GeoJSONMap geoJSONData:", geoJSONData, center);
  useEffect(() => {}, [geoJSONData]);
  return (
    <MapContainer
      style={{ height: "1000px", width: "100%" }}
      zoomControl={false}
      center={[center[0], center[1]]}
      zoom={10}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {geoJSONData && (
        <GeoJSON
          data={geoJSONData}
          pathOptions={{
            color: "#9370DB",
            fillColor: "lightblue",
            fillOpacity: 0.7,
            opacity: 1,
            weight: 1,
          }}
        />
      )}
    </MapContainer>
  );
};

export default GeoJSONMap;
