import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet";
import classes from "./DigitalLibrary.module.css";
import BreadcrumbRow from "../../components/BreadcrumbRow/BreadcrumbRow";
import { SearchBar } from "../../components/Search/SearchBar";
import { Col, Row, Tabs, Table, Drawer, Button } from "antd";
import type { TabsProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import powerpointIcon from "../../img/powerpoint.png";
import wordIcon from "../../img/word.png";
import download from "../../img/download.png";
import CheckBoxComponent from "./Sidebar";
import Sidebar from "./Sidebar";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { AnyObject } from "antd/es/_util/type";
import moment from "moment";

interface FileType {
  name: string;
}

interface DataType {
  name: string;
  created: string;
  type: string;
  size: string;
  file: string;
  id: number;
}

const StyledTable = styled(Table)`
  .ant-pagination-prev,
  .ant-pagination-next {
    display: none;
  }

  .ant-pagination-item {
    border-radius: 5px;
    background: #f5f5f5;
    min-height: 39px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6.29px 20px;
    margin: 0 20px !important;
  }

  .ant-pagination-item a {
    color: #656565;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    letter-spacing: 0.1px;
  }

  .ant-pagination-item-active {
    border-radius: 5px;
    background: #fcd980;
    box-shadow: 0px 8px 15px 0px rgba(72, 72, 138, 0.08);
    border: 0;
  }

  .ant-pagination-item-active a {
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    letter-spacing: 0.1px;
  }
`;

const DigitalLibrary: React.FC = () => {
  const { t } = useTranslation();

  const [width, setWidth] = useState(window.innerWidth)
  const [open, setOpen] = useState(width > 991 ? true : false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [columns, setColumns] = useState<ColumnsType<AnyObject>>([
    {
      title: `${t("Name")}`,
      dataIndex: "name",
      render: (text, record) => (
        <div className={classes.fileRow} key={`table-name-container-${record.id}`}>
          <div key={`table-name-div-${record.id}`}>
            <img
              key={`table-name-img-${record.id}`}
              className={classes.fileTypeIcon}
              src={record.type === "Document" ? wordIcon : powerpointIcon}
              alt="File Type"
            />
            <Link key={`table-name-link-${record.id}`} to={`/digital-library/${record.id}`} state={{fileID: record.id}}>{t(record.name)}</Link>
          </div>
        </div>
      ),
    }
  ])

  const resizeTable = () => {
    // console.log(columns)
    if (width > 920){ 
      if (!(columns.length > 3)) setColumns([...columns,
        {
          title: `${t("Size")}`,
          dataIndex: "size",
        },
        {
          title: `${t(" ")}`,
          dataIndex: "download",
          render: (text, record) => (
            <button key={`table-button-${record.id}`} className={classes.download}>
              <img 
                key={`table-button-img-${record.id}`} 
                src={download} alt="download" 
                style={{backgroundColor: '#1C1E53', padding: '.5rem', borderRadius: '10px'}}
                onClick={() => window.open(record.file, '__blank')}
              />
            </button>
          ),
        }
      ])
    } else {setColumns(columns.filter((column: any) => column.title !== 'Size' && column.title !== " "))}

    if (width > 845) {
      if(!(columns.length > 1)) setColumns([...columns,
        {
          title: `${t("Date uploading")}`,
          dataIndex: "created",
          render: (text, record) => (
            t(text)
          )
        },
        {
          title: `${t("Type")}`,
          dataIndex: "type",
          render: (text, record) => (
            t(text)
          )
        },
      ])
    } else setColumns(columns.filter((column: any) => column.title !== 'Type' && column.title !== "Date uploading"))
  
    console.log("resize")
  }

  useEffect(() => {
    //Handle Responsiveness  
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
        
    handleResize();
    
    // Bind the event listener
    window.addEventListener('resize', handleResize)

    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    resizeTable()
  }, [width, columns])
  
  const [fileTypes, setFileTypes] = useState<FileType[]>([]);
  const [data, setData] = useState<DataType[]>([]);
  const [results, setResults] = useState<any[]>([]);
  const [municipalities, setMunicipalities] = useState<any[]>([]);
  const [parkId, setParkId] = useState<string>("1");
  const [selectedFromDate, setSelectedFromDate] =
    useState<string>("01-01-1901");
  const [selectedToDate, setSelectedToDate] = useState<string>(moment(new Date()).format('DD-MM-YYYY'));
  const [selectedFileType, setSelectedFileType] = useState<string>("");
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);

  const handleDateFilterChange = (fromDate: string, toDate: string) => {
    setSelectedFromDate(fromDate);
    setSelectedToDate(toDate);
  };

  const handleFileTypeFilterChange = (fileType: string) => {
    setSelectedFileType(fileType);
  };
  const handleSearchResults = (results: DataType[]) => {
    setData(results);
    return data;
  };
  const onParkChange = (key: string) => {
    setParkId(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `${t("Hirkan")}`,
    },
    {
      key: "2",
      label: `${t("Shirvan")}`,
    },
    {
      key: "3",
      label: `${t("Absheron")}`,
      disabled: true,
    },
    {
      key: "4",
      label: `${t("Ag-Gol")}`,
      disabled: true,
    },
    {
      key: "5",
      label: `${t("Altyaghach")}`,
      disabled: true,
    },
    {
      key: "6",
      label: `${t("Göygöl")}`,
      disabled: true,
    },
    {
      key: "7",
      label: `${t("Gizilaghaj")}`,
      disabled: true,
    },
    {
      key: "8",
      label: `${t("Samur-Yalama")}`,
      disabled: true,
    },
    {
      key: "9",
      label: `${t("Shahdag")}`,
      disabled: true,
    },
    {
      key: "10",
      label: `${t("Zangezur")}`,
      disabled: true,
    },
  ];

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    fetch("https://fao-cms.etsn.az/api/file-types", {
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setFileTypes(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    if (
      parkId &&
      selectedFromDate &&
      selectedToDate &&
      (parkId === "1" || parkId === "2")
    ) {
      const url = `https://fao-cms.etsn.az/api/digital-library?park_id=${parkId}&from=${selectedFromDate}&to=${selectedToDate}&type=${selectedFileType}`;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      fetch(url, {
        headers: headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [parkId, selectedFromDate, selectedToDate, selectedFileType, accessToken]);

  return (
    <div style={{height: '80rem'}}>
      <HelmetComponent
        title={t("MENR | Digital Library") || undefined}
        description={t("SEO Digital Library description") || undefined}
      />

      <BreadcrumbRow title={"Digital Library"} />

      <div className="Container">
        <h2 className={classes.title}>{t("Digital Library")}</h2>
      </div>

      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onParkChange}
        className={classes.tabs}
      />

      <div className={classes.pageContentContainer}>
        <div className={classes.filtersContainer}>
          <ButtonWrapper type="primary" onClick={showDrawer}>
            Filters
          </ButtonWrapper>

          <DrawerWrapper
            title={
              <div className={classes.drawerTitleContainer}>
                <h2>Filters</h2>
                <Button className={classes.titleButton} onClick={onClose}>Done</Button>
              </div>
            }
            contentWrapperStyle={{ boxShadow: 'none' }}
            mask={false}
            placement="left"
            closable={false}
            onClose={onClose}
            open={open}
            getContainer={false}
          >
            <Sidebar
              fileTypes={fileTypes}
              passFileTypeFilterValues={handleFileTypeFilterChange}
              passDateFilterValues={handleDateFilterChange}
            />
          </DrawerWrapper>
        </div>
      
        <div className={classes.tabContent}>
          <SearchBar setSearchResults={handleSearchResults} parkId={parkId} />

          <StyledTable
            className={classes.table} // If needed, apply CSS module classes
            columns={columns}
            dataSource={data}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

export default DigitalLibrary;

const DrawerWrapper = styled(Drawer) `
  & { 
    width: fit-content !important;
    height: 61rem !important;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    margin-top: 24.32rem; 
  }
`

const ButtonWrapper = styled(Button) `
  & {
    margin-top: -2rem; 
    margin: 1rem;
    background-color: #1c1e53d0 !important;
  }

  &:hover {
    background-color: #1c1e53 !important;
  }
`