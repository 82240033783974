import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet";
import Header from "../../components/Header/Header";
import CardsContainer from "../../components/CardsContainer/CardsContainer";
import CTAMap from "../../components/CTAMap/CTAMap";
import FAQ from "../../components/FAQ/FAQ";

const Home: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <HelmetComponent
        title={t("MENR | Home") || undefined}
        description={t("SEO Home description") || undefined}
      />
      <Header />
      <div style={{maxWidth: "1920px", margin: "0 auto"}}>
        <CardsContainer />
        <CTAMap />
        <FAQ />
      </div>
    </>
  );
};

export default Home;
