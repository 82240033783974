import React, { useEffect, useRef } from "react";
import Chart, { ChartEvent } from "chart.js/auto";

interface DataItem {
  type: string;
  species: number;
  color: string;
}

interface BarChartProps {
  data: DataItem[];
}

const BarChart: React.FC<BarChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart<"bar"> | null>(null);

  useEffect(() => {
    const ctx = chartRef.current?.getContext("2d");
    if (!ctx) return;

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    chartInstanceRef.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: data?.map(({ type }) => type),
        datasets: [
          {
            label: "Population",
            data: data?.map(({ species }) => species),
            backgroundColor: data?.map(d => d.color),
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 5,
          },
        ],
      },
      options: {
        indexAxis: "x",
        scales: {
          x: {
            display: true,
          },
          y: {
            display: false,
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        // onHover: function (event: ChartEvent, elements: any[], chart: Chart) {
        //   if (elements.length) {
        //     chart.data.datasets[0].backgroundColor =
        //       chart.data.datasets[0].data.map((_, index) =>
        //         index === elements[0].index ? "#1C1E53" : "#F2EFFF"
        //       );
        //     chart.update();
        //   }
        // },
      },
    });

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;

};

export default BarChart;
