import React, { useEffect, useMemo, useState } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { LatLngTuple } from "leaflet";

interface MapProps {
  featureCoordinates: { [key: string]: number[][] };
}

const Map: React.FC<MapProps> = ({ featureCoordinates }) => {
  const allCoordinates = useMemo(
    () =>
      Object.values(featureCoordinates).reduce(
        (acc, coordsArray) => [...acc, ...coordsArray],
        [] as number[][]
      ),
    [featureCoordinates]
  );

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    //Handle Responsiveness  
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
        
    handleResize();

    // Bind the event listener
    window.addEventListener('resize', handleResize)

    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    // console.log("All Coordinates:", allCoordinates);
  }, [allCoordinates]);

  const getCenter = (coords: number[][]): LatLngTuple | undefined => {
    const totalCoords = coords.length;
    if (totalCoords === 0) return undefined;

    const averageLatitude =
      coords.reduce((sum, coord) => sum + coord[0], 0) / totalCoords;
    const averageLongitude =
      coords.reduce((sum, coord) => sum + coord[1], 0) / totalCoords;

    return [averageLatitude, averageLongitude];
  };

  const center = useMemo(() => getCenter(allCoordinates), [allCoordinates]);
  // console.log(center);


  return (
    <MapContainer
      center={[38.737540476190475, 48.48784571428572 ]}
      zoom={11}
      style={{ height: "400px", width: (width > 991) ? "80%" : width }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {Object.entries(featureCoordinates).map(([species, coordinatesArray]) =>
        coordinatesArray.map((coords, index) => {
          if (coords.length === 2) {
            const [latitude, longitude] = coords;
            return (
              <Marker
                key={`${species}-${index}`}
                position={[longitude, latitude]}
              />
            );
          }
          return null;
        })
      )}
    </MapContainer>
  );
};

export default Map;
