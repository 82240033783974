import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet";
import classes from "./ParkManagement.module.css";
import BreadcrumbRow from "../../components/BreadcrumbRow/BreadcrumbRow";
import { Tabs } from "antd";
import calendarIcon from "../../img/calendar-icon.png";
import fileIcon from "../../img/file-icon.png";
import Button from "../../components/Button/Button";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const API_URL = "https://fao-cms.etsn.az/api";

interface Item {
  id: number;
  name: string;
  image: [
    {
      url: string;
      description: string;
    }
  ];
  files?: [
    {
      created?: string;
      id?: number;
      name?: string;
      size?: string;
    }
  ];
}

const ParkManagement: React.FC = () => {
  const { t } = useTranslation();
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const [activeTab, setActiveTab] = useState<string>("2");
  const [showAllItems, setShowAllItems] = useState(false);
  const [visibleItems, setVisibleItems] = useState(6);
  const [limitedItems, setLimitedItems] = useState<Item[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleShowMore = () => {
    setShowAllItems(true);
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 6);
  };

  const onTabChange = (key: string) => {
    setActiveTab(key);
    setShowAllItems(false);
    setVisibleItems(3);
  };

  const items = [
    {
      key: "2",
      label: `${t("Shirvan")}`,
    },
    {
      key: "1",
      label: `${t("Hirkan")}`,
    },
    {
      key: "3",
      label: `${t("Absheron")}`,
      disabled: true,
    },
    {
      key: "4",
      label: `${t("Ag-Gol")}`,
      disabled: true,
    },
    {
      key: "5",
      label: `${t("Altyaghach")}`,
      disabled: true,
    },
    {
      key: "6",
      label: `${t("Göygöl")}`,
      disabled: true,
    },
    {
      key: "7",
      label: `${t("Gizilaghaj")}`,
      disabled: true,
    },
    {
      key: "8",
      label: `${t("Samur-Yalama")}`,
      disabled: true,
    },
    {
      key: "9",
      label: `${t("Shahdag")}`,
      disabled: true,
    },
    {
      key: "10",
      label: `${t("Zangezur")}`,
      disabled: true,
    },
  ];

  const getCategories = async () => {
    let url = `${API_URL}/park-management/categories`;

    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    console.log("Categories: ", data);

    return data;
  };
  const getExportDirectory = async (parkId: number, query?: string) => {
    let url = `${API_URL}/park-management/categories/parks/${parkId.toString()}`;
    if (query) {
      url += `?query=${encodeURIComponent(query)}`;
    }

    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    console.log("Park ", parkId, ": ", data);
    setLimitedItems(data);

    return data;
  };

  useEffect(() => {
    getCategories();
    getExportDirectory(parseInt(activeTab));
  }, [activeTab]);

  const handleSearch = async (query: string) => {
    setSearchQuery(query);

    if (query.trim() === "") {
      await getExportDirectory(parseInt(activeTab));
      setShowAllItems(false);
      setVisibleItems(6);
      return;
    }

    const filteredItems = limitedItems.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );

    setLimitedItems(filteredItems);
  };

  return (
    <>
      <HelmetComponent
        title={t("MENR | Park Management") || undefined}
        description={t("SEO Digital Library description") || undefined}
      />
      <BreadcrumbRow title={"Park Management"} />

      <h2 className={classes.title}>{t("Park Management")}</h2>

      <Tabs
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        className={classes.tabs}
      ></Tabs>

      <div className={classes.inputContainer}>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder={`${t("Search")}...`}
        />
      </div>
      <div className={classes.tabContent}>
        <div className={classes.gridContainer}>
          {!limitedItems.length && (
            <p>{t("Sorry, no results found for your search!")}</p>
          )}
          {limitedItems.slice(0, visibleItems).map((item) => (
            <div key={item.id} className={classes.gridItem}>
              <img
                className={classes.resultImg}
                src={item.image[0].url}
                alt="File Type"
              />
              <div className={classes.resultDetailsContainer}>
                <div className={classes.resultTitle}>{t(item.name)}</div>
                <div className={classes.resultDetails}>
                  <div className={classes.resultDate}>
                    <img src={calendarIcon} alt="Calendar" />
                    {item.files?.length && item.files[0].created}
                  </div>
                  <div className={classes.resultFiles}>
                    <img src={fileIcon} alt="File" />
                    {item.files?.length && `${item.files.length} Files`}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.buttonContainer}>
          {limitedItems.length > visibleItems && (
            <Button title={t("SHOW MORE")} onClick={handleShowMore} />
          )}
        </div>
      </div>
    </>
  );
};

export default ParkManagement;
