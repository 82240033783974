import { useTranslation } from "react-i18next";
import Card from "../Card/Card";
import classes from "./CardsContainer.module.css";

const CardsContainer: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={classes.cardGridContainer}>
      <div className={classes.cardGrid}>
        <Card
          number={1}
          title={t("Environmental Data")}
          content={t("Environmental Data text")}
        />
        <Card
          number={2}
          title={t("Digital Library")}
          content={t("Digital Library text")}
        />
        <Card
          number={3}
          title={t("Geospatial Data")}
          content={t("Geospatial Data text")}
        />
        <Card
          number={4}
          title={t("Park Management")}
          content={t("Park Management text")}
        />
      </div>
    </div>
  );
};

export default CardsContainer;
