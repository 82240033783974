import React from "react";
import classes from './Button.module.css'

interface ButtonProps {
  title: string;
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({ title, onClick }) => {
  return <button className={classes.btn} onClick={onClick}>{title}</button>;
};

export default Button;