import React, { useEffect } from "react";
import { Row, Col } from "antd";
import classes from "./LoginPrompt.module.css";
import { useTranslation } from "react-i18next";
import HelmetComponent from "../Helmet";
import config from "../../config";
import { useNavigate } from "react-router-dom";

interface LoginPromptProps {
  url?: string;
}

const LoginPrompt: React.FC<LoginPromptProps> = ({ url }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogin = () => {
    const { clientId, redirectUri } = config;
    const authEndpoint = `${config.baseUrl}/oauth/authorize`;

    window.location.href = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=users_administrator&response_type=code`;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken && url) {
      window.location.href = url;
    }
  }, [navigate, url]);

  return (
    <>
      <HelmetComponent title="Login" description="Login" />

      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className={classes.container404}>
            <div className={classes.textWrapper}>
              <br />
              <h3>{t("Please login to access this content.")}</h3>
              <br />
              <br />
              <div className={classes.btnsContainer}>
                <button onClick={handleLogin}>{t("Login")}</button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LoginPrompt;
