import React from 'react';
import classes from './Checkbox.module.css'; // Import your shared styles

interface CheckboxProps {
  id: string;
  text: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  value: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ id, text, name, onChange, checked, value }) => {
  return (
    <label htmlFor={id} className={classes.checkboxLabel}>
      <input
        className={`${classes.checkboxInput} ${classes.customInput}`}
        type="checkbox"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className={classes.customCheckbox} />
      <span className={classes.inputLabel}>{text}</span>
    </label>
  );
}

export default Checkbox;
