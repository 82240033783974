import { useTranslation } from "react-i18next";
import classes from "./Header.module.css"

const Header = () => {
    const { t } = useTranslation()
    return (
        <div className={classes.headerContainer}>
            <div className={classes.InnerContainer}>                    
                <h1>{t("Home page sub")}</h1>
                <h3>{t("Home page title")}</h3>
            </div>
        </div >
    )
}

export default Header;
