import React, { useState } from "react";
import { Input } from "antd";
import { Species } from "../../../../types/types";
import { SearchOutlined } from "@ant-design/icons";
import classes from "./SectionHead.module.css";
import { useTranslation } from "react-i18next";

interface SectionHeadProps {
  icon: string;
  title: string;
  isChildrenVisible: boolean;
  toggleChildrenVisibility: () => void;
  filterCallback?: (filteredData: Species[]) => void;
  showFilters: boolean;
  // sortingOptions: (keyof Species)[];
  handleSorting: (option: keyof Species | null) => void;
  handleSearch: (inputText: string) => void;
}

const SectionHead: React.FC<SectionHeadProps> = ({
  icon,
  title,
  // sortingOptions,
  isChildrenVisible,
  toggleChildrenVisibility,
  handleSorting,
  handleSearch,
}) => {
  const { t } = useTranslation() 

  const [selectedSorting, setSelectedSorting] = useState<keyof Species | null>(
    null
  );
  const [searchText, setSearchText] = useState("");

  const handleSortingChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value as keyof Species;
    setSelectedSorting(selectedOption);
    handleSorting(selectedOption);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setSearchText(searchText);
    handleSearch(searchText);
  };

  return (
    <div className={classes.inputSectionHead} style={{maxWidth: '1298px'}}>
        <p className={classes.title}><img src={icon} style={{paddingRight: '1rem'}} alt="Icon" />{title}</p>
      <div className={classes.inputWithSelect}>
        {handleSearch.length > 0 && (
          <Input
            placeholder={`${t("Search Species")}...`}
            onChange={handleSearchChange}
            value={t(searchText)}
            style={{ marginBottom: 8 }}
            prefix={<SearchOutlined />}
          />
        )}
      </div>
    </div>
  );
};

export default SectionHead;
